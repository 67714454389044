<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :elevation="hover ? 10 : 5"
      :class="{ 'on-hover': hover }"
      class="mx-auto meu-card"
      max-width="344"
      shaped
      width="344"
      :to="to"
    >
      <v-list-item >
        <v-list-item-avatar
          size="80"
          color="#3F51B5"
          class="meu-avatar"
        >
          <v-icon class="mx-2"
            medium
            color="white"
          >
          {{icon}}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1 primary--text">
          {{title}}
          </v-list-item-title>
          <!-- <v-list-item-subtitle>{{subtitle}}</v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-account-group'
    },
    title: {
      type: String,
      default: 'title'
    },
    to: {
      type: String,
      default: '/'
    },
  }
}
</script>

<style scoped>
.v-card.on-hover {
  background-color: rgba(#FFF, 0.5);
  opacity: 0.7;
  cursor: pointer;
}

.v-card:not(.on-hover) {
  opacity: 1;
 }


.meu-avatar {
    border-radius: 20px;
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container py-2"},[_c('v-data-table',{staticClass:"elevation-1 categories-table",attrs:{"height":"68vh","headers":_vm.headers,"items":_vm.tableItems,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{key:header.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[(header.value != 'actions')?_c('span',[_vm._v("ordenar por "+_vm._s(header.text.toLowerCase())+" em ordem ascendente ou ordem descendente, respectivamente")]):_c('span',[_vm._v("Ações")])])]}}}),{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"mx-4",attrs:{"large":"","color":"primary"}},[_vm._v(" mdi-tag-text-outline ")]),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{staticClass:"veryLongTooltip",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.handleSearch(-1)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify ")])],1)]}}])},[_c('span',[_vm._v("Buscar categorias por nome ou por código de cor. ")])])],1)],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":_vm.maxCategoryFormWidth,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Nova Categoria "),_c('v-icon',{attrs:{"right":"","dark":"","large":""}},[_vm._v(" mdi-plus ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('categories-form',{attrs:{"sourceEditedItem":_vm.editedItem,"sourceEditedIndex":_vm.editedIndex},on:{"resend-data":_vm.formatAddressForChild,"close":_vm.close,"save":_vm.save}})],1),_c('v-dialog',{attrs:{"max-width":_vm.maxCategoryDeleteFormWidth,"persistent":""},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('categories-delete-form',{attrs:{"targetId":_vm.editedItem.id,"targetCategory":_vm.editedItem.name},on:{"close":_vm.closeDelete,"deleted":_vm.deleteItemConfirm}})],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.id != '67f7fd43-357c-42f9-9ccc-be2a44cc8f69')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"medium":"","color":"#FCB700"},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar categoria")])]):_c('div',[_vm._v("--")]),(item.id != '67f7fd43-357c-42f9-9ccc-be2a44cc8f69')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Excluir categoria")])]):_vm._e()]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('div',{style:({
          'background-color': item.color,
          'height': '50%',
          'width': '50%',
          'border-radius': '5px'})})]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"font-weight-black indigo--text"},[_vm._v(" Nenhuma categoria encontrada ")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('span',[_vm._v(" total de categorias encontradas: "+_vm._s(_vm.showtotalCategories)+" ")]),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":7},on:{"input":_vm.getDesiredPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
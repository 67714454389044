<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
          <session-toolbar :title="title">
            <template v-slot:items>
              <v-container class="d-flex align-center">
                <v-row>
                  <v-col 
                  cols=12
                  sm="8"
                  md="8">
                    <auto-complete :item="editedItemEditableClone.patient_name"/>
                  </v-col>
                  <v-col  
                  cols=12
                  sm="4"
                  md="4">
                    <date-picker :date="editedItemEditableClone.session_date"/>
                  </v-col>
                </v-row>
              </v-container>
            </template>
        </session-toolbar>
       
        <session-sheet />

      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import axios from 'axios'
  import SessionSheet from './SessionSheet'
  import AutoComplete from './AutoComplete'
  import DatePicker from './DatePicker'
  import SessionToolbar from './SessionToolBar'
  import { mapState, mapGetters } from 'vuex'

  //axios.defaults.baseURL = 'http://localhost:8000/'
  export default {
    name: 'SessionDialog',
    
    computed: {
      ...mapGetters('sessions', {
        editedItem: 'editedItem',
        dialogStatus: 'dialogStatus',
        editedIndex: 'editedIndex',
        editedItemEditableClone: 'editedItemEditableClone',
      }),
      
      title(){
        return this.editedIndex != -1 ? 'Editando Sessão' : 'Nova Sessão'
      },
    },
    
    components: {
      SessionSheet,
      AutoComplete,
      DatePicker,
      SessionToolbar
    },

    data: function(){
      return {
        rows: 5,
        cols: 2,
        dialog: this.dialogStatus,
        loading: false,
      }
    },

    watch: {
      dialogStatus: {
        handler(){
          this.dialog = this.dialogStatus
        }
      },
    },
  }
</script>

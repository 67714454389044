
<template>
  <div>
    <h2>oi</h2>
  <!-- <vue-simple-markdown :source="source"></vue-simple-markdown> -->
  </div>
</template>
<script>

export default {
 data(){
  return {
    markdown:  "Hello World",
    // source: `# title ${}`
    a: 'vvlorem qwe qwe qweq we wqe qeeqwewqelorem qwe qwe qweq we wqe qeeqwewqe',
    b: 'eir2ijij34342'
  };
 },
 computed: {
   source (){
    const txt = `## Tranvisão\n> ${this.markdown}\n> ok`
    return  txt
   }
 }
}
</script>
<style>

</style>
<template>
   <v-toolbar
      dark
      color="primary"
      max-height="55px"
    >
    </v-btn>
      <v-toolbar-title class="mx-2">{{title}}</v-toolbar-title>
    <v-toolbar-items>
      <slot name="items"></slot>
    </v-toolbar-items>
    
   <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" color="white" to="/patients" depressed small class="d-flex links-bernal mx-2">
          <v-icon class="mx-2"
              medium
              color="primary"
            >
            mdi-account-group
          </v-icon>
        </v-btn>
      </template>
      <span>Pacientes</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" color="white" depressed to="/categories" small class="d-flex links-bernal mx-1">
          <v-icon class="mx-2"
            medium
            color="primary"
          >
            mdi-tag-text-outline
          </v-icon>
        </v-btn>
         </template>
      <span>Categorias</span>
    </v-tooltip>


    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" color="white" depressed small to="/dicts" class="d-flex links-bernal mx-1">
          <v-icon class="mx-2"
            medium
            color="primary"
          >
          mdi-book-open-variant
          </v-icon>
        </v-btn>
      </template>
      <span>Dicionário</span>
    </v-tooltip>


    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" color="white" depressed small to="sessions" class="d-flex links-bernal mx-1">
          <v-icon class="mx-2"
            medium
            color="primary"
          >
            mdi-clipboard-multiple-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Sessões</span>
    </v-tooltip>
    
     <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" color="white" depressed to="/dashboard" small class="d-flex links-bernal mx-1">
            <v-icon class="mx-2"
              medium
              color="primary"
            >
              mdi-desktop-mac-dashboard 
            </v-icon>
        </v-btn>
      </template>
      <span>Dashboard</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" color="white" depressed to="/transvisions" small class="d-flex links-bernal mx-1">
            <v-icon class="mx-2"
              medium
              color="primary"
            >
            mdi-eye-settings-outline
            </v-icon>
        </v-btn>
      </template>
      <span>Transvisões</span>
    </v-tooltip>

     <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" color="white" to="/" depressed small class="d-flex links-bernal mx-1">
          <v-icon class="mx-2"
            medium
            color="primary"
          >
            mdi-home
          </v-icon>
        </v-btn>
      </template>
      <span>Home</span>
    </v-tooltip>
    
    <v-spacer></v-spacer>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn @click="logout" v-on="on" color="white" depressed small class="d-flex links-bernal mx-1">
          <v-icon class="mx-2"
            medium
            color="primary"
          >
            mdi-logout
          </v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
    
  </v-toolbar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Sistema de consultas'
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },

    async saveSession(){
   
      await this.$store.dispatch('sessions/saveSession')
      this.$store.dispatch('sessions/setDialogStatus', false)
    },
    close (){
      this.$store.dispatch('sessions/setDialogStatus', false)
  }
  }
}
</script>

<style>
.links-bernal {
  background-color: white !important;
  border-radius: 10px;
  
}

::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #131B69; /* #3F51B5; */
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0D134E; /* #2e3b84; */
}

</style>
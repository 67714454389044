<template>
  <dictionary-map-table
    :title="title"
    />
</template>

<script>
  import axios from 'axios'

  import DictionaryMapTable from '../components/dictionaries/DictionaryMapTable'

  //axios.defaults.baseURL = 'http://localhost:8000/'

  export default {
    name: 'Dictionary',

    components: {
      DictionaryMapTable,
    },

    data: () => ({
      title: 'Dicionário',
    }),
  }

</script>


<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-autocomplete
        v-on="on"
        v-model="category"
        :rounded="true"
        outlined
        dense
        label="categoria"
        height="30"
        :items="finalItems"
        @change="setCategory"
      > 
    </v-autocomplete>
    </template>
    <span>Filtro de categoria</span>
  </v-tooltip>
</template>

<script>
// NAO USEI NO TOPIC, MAS PODERIA... MAS FIZ EM OUTRO MOMENTO E N QUERO CRIAR PROBLEMAS
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      type: String
    },
    catFlag: { // distinguir no dashboard qual categoria foi escolhida: A ou B
      type: String
    }
  },

  data: function(){
    return {
      dashBoardRoute: 'Dashboard',
      category: '',
      items: [],
      itemsIdMap: []
    }
  },

  mounted (){
    this.getCategories()
  },

  computed: {
    ...mapGetters('sessions', {
      categories: 'categories'
    }),

    ...mapGetters('dashboard', {
      categoryA: 'categoryA',
      categoryB: 'categoryB',

    }),

    finalItems: {
      get: function(){
        return this.items
      },
      set: function(v){
        this.items = v.map(({ name }) => name)
        this.itemsIdMap = v.map(it => ({ id: it.id, name: it.name }))
        this.items.unshift('--')
      }
    }
    
  },

  methods: {
    async setCategory(cat){
      this.category = cat
      var data = undefined
      const categoryObj = this.itemsIdMap.find(it => it.name === cat)
      const flag = this.catFlag
      const storePath = flag == 'A' ? 'setCategoryA' : 'setCategoryB'

      if (categoryObj){
        data = {
          id: categoryObj.id,
          category: cat
        }
      }
      await this.$store.dispatch(`dashboard/${storePath}`, data)
    },
    async getCategories(){
      if (this.categories.length == 0)
        await this.$store.dispatch('sessions/getCategories')
        
      this.finalItems = this.categories  
      this.category = this.finalItems[0]
    },
  }
}
</script>

<style scoped>
.categories-options {
  border-radius: 10px;
}
</style>
<template>
  <v-autocomplete
    hide-details
    v-model="finalItem"
    :disabled="disabledPatient"
    :rounded="true"
    placeholder="Nome do paciente"
    dense
    height="30"
    class="categories-options"
    :items="patients.map(({ name }) => name)"
    return-object 
    @change="setItem">
  </v-autocomplete>
</template>

<script>
// APESAR DO NOME, ELE DEVIA SER PatientAutoComplete ou algo do tipo!!! refatorar dps
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    item: {
      type: String
    }
  },

  data: function(){
    return {
      innerItem: this.item,
      dashBoardRoute: 'Dashboard'
    }
  },

  computed: {
    ...mapGetters('sessions', {
      editedItemEditableClone: 'editedItemEditableClone',
      editedIndex: 'editedIndex',
      patients: 'patients',
    }),

    disabledPatient: function() {
      return (this.editedIndex != -1 && this.$route.name != this.dashBoardRoute) ? true : false
    },

    finalItem: {
      get: function (){
        // codigo nem um pouco maduro...pqp
        if(this.$route.name == this.dashBoardRoute && this.item && this.patients.length > 0){
          const newPatient = this.patients.find(obj => obj.name === this.item)
          const data = {
            patient: {id: newPatient.id, name: newPatient.name}
          }
          this.$store.dispatch('dashboard/setPatient', data)
        }
        return this.item
      },

      set: function (item){
        if (!item)
          item = this.item
          
        this.innerItem = item
        var editedItemEditableCloneCopy = Object.assign({}, this.editedItemEditableClone)
        const newPatient = this.patients.find(obj => obj.name === item)
      
        editedItemEditableCloneCopy.patient = newPatient.id
        editedItemEditableCloneCopy.patient_name = newPatient.name

        const data = {
          patient: {id: newPatient.id, name: newPatient.name}
        }
        if (this.$route.name != this.dashBoardRoute){
          this.$store.dispatch('sessions/setPatient', data)
        } else 
          this.$store.dispatch('dashboard/setPatient', data)
      }
    }
  },

  methods: {
    setItem(name){
      if (!name)
        this.$emit('disableDashboardSearchBtn', true)
      else 
        this.$emit('disableDashboardSearchBtn', false)  
    }
  }
}
</script>


<template>
   <v-container  class="pa-0" fluid fill-height>
    

    <v-layout column justify-center align-center>
      <v-col class="d-flex align-center justify-center">
       <v-card width="600px " class="card-search elevation-12">

          <v-toolbar
            color="primary"
            dark
            flat
          >
          <v-toolbar-title>Acesso ao sistema</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-snackbar width="600px"
              v-model="snackbar"
              :timeout="2500"
              :color="notificationData.color"
              botton
              >
              <span> {{notificationData.msg}} </span>
            </v-snackbar>
          </v-toolbar>
          <!-- <v-slide-x-transition hide-on-leave> -->
            <v-card-text>
              <v-form>
                <div v-if="logging">
                  autenticando usuário...
                  <v-progress-linear 
                    indeterminate
                    color="blue darken-2"
                  ></v-progress-linear>
                </div>

                <v-row justify="center">
                  <v-col cols=10>
                    <v-text-field
                      label="Login"
                      autocomplete="new-password"
                      v-model.trim="email"
                      append-icon="mdi-account"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols=10>
                    <v-text-field
                      label="Senha"
                      autocomplete="new-password"
                      v-model.trim="password"
                      :append-icon="isShowing ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="(isShowing) ? 'text': 'password'"
                      @click:append="isShowing = !isShowing"
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-form>
            </v-card-text>
          <!-- </v-slide-x-transition>
           -->
          <!-- <v-slide-x-transition hide-on-leave> -->
            <v-card-text>
              <v-row justify-center>
                  <v-col cols=12>
                    <v-btn :disabled="email && password ? false : true" @click="login" minWidth="500px" maxHeight="50px" x-large color="primary">Entrar</v-btn>
                  </v-col>
                  <v-col cols=12>
                    <span><u class="forgot-pass" @click="openMailSend">Esqueci minha senha</u></span>
                    <!-- <v-btn minWidth="500px" maxHeight="50px" x-large color="warning" dark>Esqueci minha senha</v-btn> -->
                  </v-col>
              </v-row>
            </v-card-text>
          <!-- </v-slide-x-transition>           -->
        </v-card>
      </v-col>
      <v-dialog 
          v-model="dialog" 
          max-width="500px"
          persistent>
            <pass-recover
              targetId="1"
              targetEmail="test@gmail.com"
              @close="closeMailSend"
              @notify="sendMailStatus"
            />
      </v-dialog>  
      <v-card
        class="my-5 mx-auto"
        max-width="400"
        tile
      >
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'
import router from '../router'

import { sum } from '../api/index.js'
import passRecover from '../components/login/passRecover.vue'

  export default {
    components: {
      passRecover
    },

    data: function (){
      return {
        dialog: false,
        email : "",
        password : "",
        logging: false,
        isShowing: false,
        snackbar: false,
        notificationData: {
          color: "error",
          msg: "Usuário ou senha inválidos"
        }
      }
    },

    async mounted(){ 
      if (this.$route.params.changed)
        this.noticationHandler("success", "senha alterada com sucesso", true)
      const status = await this.$store.dispatch('auth/ping')
      if(status && this.$route.name === 'Login'){ // ja tive logado e for pra pag de login, va pra home
        router.push('/')
      }
    },

    methods: {
      async openMailSend(){
        this.dialog = true
      },
      
      noticationHandler(color, msg, status){
        this.notificationData.color = color//"success"
        this.notificationData.msg = msg
        this.snackbar = status
      },

      sendMailStatus(data){
        this.noticationHandler(data.color, data.msg, true)
      },

      closeMailSend (){
        this.dialog = false
      },

      async login () {
        this.logging = true
        const username = this.email
        const password = this.password
        const success = await this.$store.dispatch('auth/login', { username, password }) //.then(() => {
        if (!success){
          this.noticationHandler("error", "Usuário ou senha inválidos.", true)
        }
         
        this.logging = false
      }
    },
    
    computed: {
      passwordErrors () {
        const errors = []
        // if (!this.$v.passwor.$dirty) return errors
        // !this.$v.password.maxLength && errors.push('Name must be at most 10 characters long')
        // !this.$v.password.required && errors.push('Name is required.')
        return errors
      },

      emailErrors () {
        const errors = []
        // if (!this.$v.email.$dirty) return errors
        // !this.$v.email.email && errors.push('Must be valid e-mail')
        // !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
    }
  }
</script>
<style scoped>
  .forgot-pass {
    cursor: pointer;
  }
</style>
<template>
  <v-container class="container py-2">
    <v-data-table
      height="68vh"
      :headers="headers"
      :items="tableItems"
      :loading="loading"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 transvisions-table"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-icon class="mx-4"
            large
            color="primary"
          >
            mdi-eye-settings-outline
          </v-icon>

          <v-toolbar-title > {{title}} </v-toolbar-title>
          <v-divider 
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <!-- <v-spacer></v-spacer> -->
          <v-row no-gutters>
            <v-col cols=11>
              <v-text-field
                v-model.trim="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols=1>
              <v-btn
                @click="handleSearch(-1)"
                class="mx-2"
                fab
                small
                depressed
                color="primary"
              >
                <v-icon dark>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            :max-width="maxTransvisionFormWidth"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Nova Transvisão
                <v-icon
                  right
                  dark
                  large
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
              
            <transvision-form
              :sourceEditedItem="JSON.parse(JSON.stringify(editedItem))"
              :sourceEditedIndex="editedIndex"
              @close="close"
              @save="save"
              @patients="handlePatients"
            />


          </v-dialog>
          <v-dialog 
            v-model="dialogDelete" 
            :max-width="maxTransvisionDeleteFormWidth"
            persistent>
              <transvision-delete-form
                :transvisionId="editedItem.id"
                @close="closeDelete"
                @deleted="deleteItemConfirm"/>
          </v-dialog>  
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              class="mr-2"
              @click="editItem(item)"
              medium
              color="#FCB700"
            >
              mdi-pencil
             </v-icon>
          </template>
          <span>Editar Transvisão</span>
        </v-tooltip>

        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            class="mr-2"
            @click="templateHandler(item)"
            medium
            color="primary"
          >
            mdi-content-save
          </v-icon>
        </template>
        <span>Gerar pdf</span>
        </v-tooltip>

        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            @click="deleteItem(item)"
            medium
            color="red"
          >
            mdi-delete
          </v-icon>
           </template>
        <span>Excluir Transvisão</span>
        </v-tooltip>
      
      </template>
      <!-- -->
      <template v-slot:item.color="{ item }">
          <div :style="{
            'background-color': item.color,
            'height': '50%',
            'width': '50%',
            'border-radius': '5px'}">
          </div> 
      </template>

      <!-- qd nao tiver item cadastrado -->
      <template v-slot:no-data>
        <p class="font-weight-black indigo--text">
          Nenhuma transvisão encontrada
        </p>
      </template>
      <template v-slot:footer>
        <div class="text-center">
          <span> total de transvisões encontradas: {{showtotalTransvisions}} </span>
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="getDesiredPage"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>
  
<script>
import axios from 'axios'
import TransvisionForm from './TransvisionForm.vue'
import transvisionDeleteForm from './TransvisionDeleteForm.vue'

import { getPatient, listTransvisions,  generateDoc } from '../../api'
  export default {
    props: {
      title: String
    },

    components: {
      TransvisionForm,
      transvisionDeleteForm,
    },

    data: () => ({
      search: '',
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Identificação', value: 'id', class: 'white--text' },
        { text: 'Identidade (fictícia)', value: 'identidade', class: 'white--text' },
        { text: 'Terapeuta', value: 'terapeuta', class: 'white--text' },
        { text: 'Data', value: 'data_tv', class: 'white--text' },
        { text: 'Ações', value: 'actions', sortable: false, class: 'white--text' },
      ],
      tableItems: [],
      defaultItem: {
        id: "", 
        patient: "",
        patient_name: "--", 
        identidade: "--", 
        setenio: "",
        data_tv: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
        numero_transvisao: "--", // trocar para numero_transvisoes
        intervalo_ultima_sessao: "", // trocar para intervalo_ultima_transvisao
        terapeuta: "--",
        genero: {genero: "Masculino", abbr: "M"},
        gender_display: "Masculino",
        idade: "--",
        estado_civil: { civilState: 'Casado', abbr: 'C' },
        estado_civil_display: "Casado", 
        casamentos: "--",
        domicilio: { domicile: 'Só', abbr: 'SO' },
        domicilio_display: "Só",
        acompanhado_com: "--",
        profissao_trabalho: { profission: '0 = não', abbr: '0' },
        profissao_trabalho_display: "0 = não",
        profissao_financeiro: { remuneration: '0 = não remunerado', abbr: '0' },
        profissao_financeiro_display: '0 = não remunerado',
        dm_passado_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dm_passado_m_display: '1 = precário; ausente ou quase ausente',
        dm_passado_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dm_passado_p_display: '1 = precário; ausente ou quase ausente',
        dp1_passado_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp1_passado_m_display: '1 = precário; ausente ou quase ausente',
        dp1_passado_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp1_passado_p_display: '1 = precário; ausente ou quase ausente',
        dp2_passado_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp2_passado_m_display: '1 = precário; ausente ou quase ausente',
        dp2_passado_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp2_passado_p_display: '1 = precário; ausente ou quase ausente',
        df_passado: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        df_passado_display: '1 = precário; ausente ou quase ausente',
        dm_presente_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dm_presente_m_display: '1 = precário; ausente ou quase ausente',
        dm_presente_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dm_presente_p_display: '1 = precário; ausente ou quase ausente',
        dp1_presente_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp1_presente_m_display: '1 = precário; ausente ou quase ausente',
        dp1_presente_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp1_presente_p_display: '1 = precário; ausente ou quase ausente',
        dp2_presente_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp2_presente_m_display: '1 = precário; ausente ou quase ausente',
        dp2_presente_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        dp2_presente_p_display: '1 = precário; ausente ou quase ausente',
        df_presente: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        df_presente_display: '1 = precário; ausente ou quase ausente',
        da1_presente: { dinamismoA: '0 = precário; ausente ou quase ausente', abbr: '0' },
        da1_presente_display: '1 = precário; ausente ou quase ausente',
        da2_presente: { dinamismoA: '0 = precário; ausente ou quase ausente', abbr: '0' },
        da2_presente_display: '0 = precário; ausente ou quase ausente',
        vla: { matriz: 'M >', abbr: 'MG' },
        vla_display: 'M >',
        v4: { matriz: 'M >', abbr: 'MG' },
        v4_display: 'M >',
        v5: { matriz: 'M >', abbr: 'MG' },
        v5_display: 'M >',
        familia_de_origem: '',
        familia_de_origem_mortos: '',
        famila_descendente: '',
        familia_descendente_mortos: '',
        motivo_busca_terapia: '',
        terapias_anteriores: '',
        terapias_atual: '',
        condicao_saude_doenca_psiquica: '',
        condicao_saude_doenca_importante: '',
        condicao_saude_tratamento: '',
        familiar: '',
        pessoal: '',
        social: '',
        profissional: '',
        espiritual: '',
        dinamica_familiar: '',
        desafio: '',
        compreensoes: [{'description': '--'},{'description': '--'}]
      },
      maxTransvisionFormWidth: '1200px',
      maxTransvisionDeleteFormWidth: '500px',
      loading: true,
      items: [],
      totalTransvisions: 0, // vem da api
      currentPage: 1,
      pageControler: 1, // evitar que chame a api qd chamar uma mesma pagina
      itemsPerPage: 8, // por de acordo com oq eu definir no backend
      editedIndex: -1,
      editedItem: {},
    }),
    
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nova Categoria' : 'Editando Categoria'
      },

      showtotalTransvisions: {
        get: function(){
          return this.totalTransvisions

        },
        set: function(v){
          this.totalTransvisions = v
        }
      },

      totalPages (){
        var ammount = Math.ceil(this.totalTransvisions / this.itemsPerPage)
        return (isNaN(ammount) || ammount == 0 ? 1 : ammount)
      },
    },

    mounted () {
      this.getTransvisions(this.currentPage)
      this.setDefaultItem()
    },
    
    methods: {
      async seedUser(pk){
        return await getPatient(pk)
        .then(res => {
           return res.data.name
        })
        .catch(err => {
          if(err.response){ // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response:")
          } 
          else if (err.request)// response nunca recebida
            console.log("error: never received a response: ")
          else 
            console.log("error: ")
          return false
        })

      },

      handlePatients(data){
        //console.log("lala ", data)
      },  

      handleSearch(page = -1){
        if (page == -1){
          this.currentPage = 1
          this.pageControler = 1
          page = 1 // pra buscar primeira do filter query
        }
        if (this.search){
          this.getTransvisions(page, `&search=${this.search}`)
        }
        else{
          this.getTransvisions(page)
        }
      },

      getDesiredPage(v){ //v == this.currentPage por conta do v-model
        if (this.pageControler != v){
          //this.getTransvisions(v)
          this.handleSearch(v)
          this.pageControler = this.currentPage
        }
      },

      async getTransvisions (targetPage, qs = '') {
        this.loading = true
        listTransvisions({"page": targetPage, "qs": qs})
        .then(res => {
          if (res && res.data && res.data.results){
            var data = res.data.results
            this.tableItems = data
            this.totalTransvisions = res.data && res.data.count ? res.data.count : 0
          }
        })
        .catch(err => {
          if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
          }
          else if (err.request) { // response nunca recebida
            console.log("error: never received a response: ")
          }
          else {
            console.log("error: ")
          }
        })
        this.loading = false
      },

      async editItem (item) {
        item.patient_name = await this.seedUser(item.patient)
        this.patient_name = item.patient_name
        this.editedIndex = this.tableItems.indexOf(item)
        this.editedItem = item
        // this.formatAddressForChild(item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.tableItems.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.tableItems.splice(this.editedIndex, 1)
        this.totalTransvisions -=1
        this.closeDelete()
      },

      setDefaultItem() {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },

      setDate(date){
        this.editedItem.birth_date = date
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      templateHandler(data){
        this.loading = true
        generateDoc(data)
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = 'transvision.ods'
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
          }
          else if (err.request) { // response nunca recebida
            console.log("error: never received a response: ")
          }
          else {
            console.log("error: ")
          }
        })
        this.loading = false
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      
      save (data){
        if (this.editedIndex > -1){ // editando transvsisaso
          Object.assign(this.tableItems[this.editedIndex], data)
          this.close()
        }
        else { // nova transvisao
          this.tableItems.unshift(data)
          this.close()
          this.showtotalTransvisions += 1
        }
      }
    },
  }
</script>

<style>
  

  .transvisions-table table {
    border: 5px solid #3F51B5; /** #B359CA*/
    border-radius: 10px;
  }

  .transvisions-table td  {
    border: 1px solid #3F51B5;  /** #B359CA*/
    background-color: white;  /**#883A9B*/
    /* text-align: center; */
  }
  
  .transvisions-table td.text-start {
    color:  black; /**cor dos itens antiquewhite;*/
    font: 14px;
    font-weight: normal; /**bold */
  }
  
  .transvisions-table  th {
    background-color: #3F51B5;  /** #B359CA*/
    border: 2px solid #3F51B5;  /** #B359CA*/
  }
  
</style>
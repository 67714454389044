<template>
  <v-container class="container py-2"> <!-- py-2"> -->
    <v-data-table
      height="68vh"
      :headers="headers"
      :items="tableItems"
      :loading="loading"
      :items-per-page="itemsPerPage"
      hide-default-footer
      
      class="elevation-1 patients-table"
    >
      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
        <v-tooltip bottom :key="header.value" >
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ header.text }}</span>
          </template>
          <span v-if="header.value != 'actions' ">ordenar por {{ header.text.toLowerCase() }} 
            em ordem de criação, ordem ascendente ou ordem descendente, respectivamente</span>
          <span v-else>Ações</span>

        </v-tooltip>

      </template>

      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-icon class="mx-4"
            large
            color="primary"
          >
            mdi-account-group
          </v-icon>
          <v-toolbar-title > {{title}} </v-toolbar-title>
          <v-divider 
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <!-- <v-spacer></v-spacer> -->
          <v-row no-gutters>
            <v-col cols=11>
              <v-text-field
                v-model.trim="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols=1>
              <v-tooltip bottom class="veryLongTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  @click="handleSearch(-1)"
                  class="mx-2"
                  fab
                  small
                  depressed
                  color="primary"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>
                <span>Buscar parcientes por nome,
                  e-mail, cpf, telefone, data de nascimento ou gênero.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            :max-width="maxPatientFormWidth"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Novo Paciente
                <v-icon
                  right
                  dark
                  large
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            
            <patients-form
              :sourceEditedItem="editedItem"
              :sourceEditedIndex="editedIndex"
              @resend-data="formatAddressForChild"
              @close="close"
              @save="save"
            />

          </v-dialog>
          <v-dialog 
            v-model="dialogDelete" 
            max-width="500px"
            persistent>
              <patients-delete-form
                :targetId="editedItem.id"
                :targetEmail="editedItem.email"
                @close="closeDelete"
                @deleted="deleteItemConfirm"/>
          </v-dialog>  
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            class="mr-2"
            @click="editItem(item)"
            medium
            color="#FCB700"
          >
            mdi-pencil
          </v-icon>
        </template>
      <span>Editar paciente</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            @click="deleteItem(item)"
            medium
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
      <span>Excluir paciente</span>
      </v-tooltip>
      </template>
  

      <!-- qd nao tiver item cadastrado -->
      <template v-slot:no-data>
        <p class="font-weight-black indigo--text">
          Nenhum paciente encontrado
        </p>
      </template>
      <template v-slot:footer>
        <div class="text-center">
          <span class="text-center"> total de pacientes encontrados: {{showTotalPatients}} </span>
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="getDesiredPage"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>
  
<script>
  import axios from 'axios'
  import BirthDay from './BirthDay'
  import PatientsForm from './PatientsForm'
  import PatientsDeleteForm from './patientsDeleteForm'

  export default {
    props: {
      title: String
    },

    components: {
      BirthDay,
      PatientsForm,
      PatientsDeleteForm
    },

    data: () => ({
      search: '',
      dialog: false,
      dialogDelete: false,
      genderOptions: [
        { gender: 'Masculino', abbr: 'M' },
        { gender: 'Feminino', abbr: 'F' },
        { gender: 'Outro', abbr: 'O' },
      ],
      headers: [
        // { text: 'Id', value: 'id', class: 'white--text' },
        { text: 'Dias', value: 'days_week', class: 'white--text' },
        { text: 'Nome', value: 'name', class: 'white--text' },
        { text: 'E-mail', value: 'email', class: 'white--text' },
        { text: 'Cpf', value: 'cpf', class: 'white--text' },
        { text: 'Telefone', value: 'phone', class: 'white--text' },
        { text: 'Data de Nascimento', value: 'birth_date', class: 'white--text' },
        { text: 'Gênero', value: 'gender_display', class: 'white--text' },
        { text: 'Ações', value: 'actions', sortable: false, class: 'white--text' },
      ],
      tableItems: [],
      defaultItem: {
        id: "", 
        name: "", 
        email: "", 
        phone: "--", 
        cpf: "--",
        birth_date: "",
        gender: {gender: "Masculino", abbr: "M"},
        gender_display: "Masculino",
        region: "--",
        city: "--",
        district: "--",
        street: "--",
        number: "--",
        zip_code: "--",
        complement: "--"
      },
      maxPatientFormWidth: '1000px',
      loading: true,
      items: [],
      totalPatients: 0, // vem da api
      currentPage: 1,
      pageControler: 1, // evitar que chame a api qd chamar uma mesma pagina
      itemsPerPage: 8, // por de acordo com oq eu definir no backend
      editedIndex: -1,
      editedItem: {},
    }),
    
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Novo Paciente' : 'Editando Paciente'
      },

      showTotalPatients (){
        return this.totalPatients
      },

      totalPages (){

        var ammount = Math.ceil(this.totalPatients / this.itemsPerPage)
        return (isNaN(ammount) || ammount == 0 ? 1 : ammount)
      },
    },

    mounted () {
      this.getPatients(this.currentPage)
      this.setDefaultItem()
    },
    
    methods: {
      refresh () {
        this.$store.dispatch('auth/refresh')
      },

      teste (){
        console.log("--")
      },
      handleSearch(page = -1){
        if (page == -1){
          this.currentPage = 1
          this.pageControler = 1
          page = 1 // pra buscar primeira do filter query
        }
        if (this.search){
          this.getPatients(page, `&search=${this.search}`)
        }
        else{
          this.getPatients(page)
        }
      },

      getDesiredPage(v){ //v == this.currentPage por conta do v-model
        if (this.pageControler != v){
          //this.getPatients(v)
          this.handleSearch(v)
          this.pageControler = this.currentPage
        }
      },

      async getPatients (targetPage, qs = '') {
        this.loading = true
        const token = `token ${localStorage.getItem('user-token')}`
        await axios.get(
          `/sessions/patients/?page=${targetPage}${qs}`, {
            // headers: {
            //   "Authorization": token
            // }
          })
        .then(res => {
          this.tableItems = res.data && res.data.results ? res.data.results : [{}]
          this.totalPatients = res.data && res.data.count ? res.data.count : 0
        })
        .catch(err => {
          if(err.response){// client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
          } 
          else if (err.request)// response nunca recebida
            console.log("error: never received a response: ")
          else 
            console.log("error: ")
        })
        this.loading = false
      },

      async editItem (item) {
        this.editedIndex = this.tableItems.indexOf(item)
        this.formatAddressForChild(item)
        this.dialog = true
      },

      formatAddressForChild(item){
        item = { ...item, ...item.address }
        delete item.address
        this.editedItem = Object.assign({}, item)
      },

      deleteItem (item) {
        this.editedIndex = this.tableItems.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.tableItems.splice(this.editedIndex, 1)
        this.totalPatients -=1
        this.closeDelete()
      },

      setDefaultItem() {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },

      setDate(date){
        this.editedItem.birth_date = date
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      
      save (data){
        if (this.editedIndex > -1){ // editando paciente
          Object.assign(this.tableItems[this.editedIndex], data)
          this.close()
        }
        else { // novo paciente
          this.tableItems.unshift(data)
          this.close()
          this.totalPatients += 1
        }
      }
    },
  }
</script>

<style>
  .patients-table table {
    border: 5px solid #3F51B5; /** #B359CA*/
    border-radius: 10px;
  }

  .patients-table td  {
    border: 1px solid #3F51B5;  /** #B359CA*/
    background-color: white;  /**#883A9B*/
    /* text-align: center; */
  }
  
  .patients-table td.text-start {
    color:  black; /**cor dos itens antiquewhite;*/
    font: 14px;
    font-weight: normal; /**bold */
  }
  
  .patients-table  th {
    background-color: #3F51B5;  /** #B359CA*/
    border: 2px solid #3F51B5;  /** #B359CA*/
  }
  
 
</style>
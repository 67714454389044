<template>
  <v-card>
    <v-card-title class="text-h6 white--text indigo">
      Recuperação de acesso
      <v-spacer/>
      <v-btn
        icon
        dark
        @click="close"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    
    <v-card-text>
      
       <v-container>
         <v-row class="d-flex justify-center" no-gutters>
          <v-col cols=6>
            <v-text-field
              autofocus
              v-model="email"
              label="E-mail"
              @input="v => {email = v.toLowerCase().replace(/ /g, '')}"
              :rules="emailRules"
              required
            ></v-text-field>
          </v-col>
         </v-row>
          <div v-if="loading">
            <v-progress-linear 
              indeterminate
              color="blue darken-2"
            ></v-progress-linear>
          </div>
       </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-divider dark inset></v-divider>
        <v-col cols=12 align-self="center">  <!-- class="text-right"-->
          <v-btn :disabled="email ? false : true" class="ma-1" width='25vh' color="primary" white--text @click="sendMail">Enviar</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import { recover } from '../../api/index'

export default {
  data: function (){
    return {
      email: '',
      loading: false,
      emailRules: [ // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v)
        // v => !!v || 'O E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'Digite um E-mail válido',
      ],
    }
  },

  methods: {
    close () {
      this.$emit('close')
      this.email = ''
    },

    async sendMail(){
      this.loading = true
      
      await recover({"email": this.email})
      .then(res => {
        this.$emit('notify', {msg: "E-mail enviado com sucesso", color: "success"})
        this.close()
      })
      .catch(err => {
        if(err.response){
           // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
          this.$emit('notify', {msg: "E-mail inválido", color: "error"})
          this.close()
        }
        else if (err.request) // response nunca recebida
          console.log("error: never received a response: ")
        else
          console.log("error: ")
      })
      this.email = ''
      this.loading = false
    }
  }
}
</script>
  
  import axios from 'axios'

  const state = () => ({
    countSession: 0,
    sessions: [],
    topics: [],
    patients: [],
    categories: [],
    editedItem: {},
    totalSessionsDb: 0,
    editedItemEditableClone: {},
    editedIndex: -1,
    dictionary: [],
    dialogStatus: false,
    loading: false,
    topicLen: 0,
    sessionSnackBarHandler: {
      desiredStatus: false,
      status: false,
    }
  })

  const mutations =  {
    increment(state){
      state.countSession += 1
      const category = {name: 'elaine'}
      //state.sessions = []
      // state.sessions = [
      // ...state.sessions.filter(element => element.id !== '39f61413-112c-45d3-8c17-b473431cebe9'),
      //  category
      // ]
    },
    setSnackBarDesiredStatus(state, status){ //usado pelo caso de erro      
      state.sessionSnackBarHandler.desiredStatus = status
      
    },
    setSnackBarStatus(state){
      if (state.sessionSnackBarHandler.desiredStatus == true){
        state.sessionSnackBarHandler.status = true
      }
      else{
        state.sessionSnackBarHandler.status = false
      }
      // bota o valor padrao
      state.sessionSnackBarHandler.desiredStatus = false
    },
    rmvDesiredRows(state){
      state.topicLen -= 1
    },

    addDesiredRows(state){
      state.topicLen += 1
    },

    setCategories(state, categories){
      // console.log("setando categorias: ", categories)
      state.categories = categories
    },
    setDict(state, dictionary){
      // console.log("setando dicionary: ", dictionary)
      state.dictionary = dictionary
    },
    setSessions(state, payload){
      state.sessions = payload
    },
    removeSession(state, tgtSession){
      state.sessions.splice(tgtSession, 1)
    },
    setSession(state, payload){
      const updateSession = payload.editedItem
      state.sessions = [ // JEITO CORRETO DE FAZER O UPDATE!
        ...state.sessions.filter(session => session.id !== payload.editedItem.id),
        updateSession
      ]
      // parece pesado mas vida que segue
      state.sessions.forEach((s,i ) => {
          console.log("--")
      });
      state.sessions.sort((a, b) => b.created_at.localeCompare(a.created_at))

    },
    setPatients(state, payload){
      state.patients = payload
    },
    setTotalSessionsDb (state, ammount) {
      state.totalSessionsDb = ammount
    },
    setDialogStatus(state, status){
      state.dialogStatus = !state.dialogStatus//status
    },
    setItem(state, editedItem){
      state.editedItem = editedItem
      // novo
      state.topics = editedItem.topics
      // fim novo
      state.topicLen = Math.ceil(editedItem.topics.length/2)
    },

    setEditableItemClone(state, data){
      // AQUI Q FAZ A MAGICA: COPIA EM VEZ DE REFERENCIA. OBSERVE Q OBJECT,ASSIGN NAO COPIA..MANTEM A REF SEI LA PQ
      state.editedItemEditableClone = JSON.parse(JSON.stringify(data.editedItem))// Object.assign({}, data.editedItem) //JSON.parse(JSON.stringify(data.editedItem))//Object.assign({}, data.editedItem)
      state.topicLen = Math.ceil(state.editedItem.topics.length/2)
    },
    setTopic(state, data){
      var cp = Object.assign({}, data)
      delete cp.topic_category_details.description
      delete cp.topic_category_details.title
      delete cp.topic_category_details.topicName
      // safe pois data é copy de editedItem e nao uma ref pra ele ;)
      state.editedItemEditableClone.topics[data.topicIndex] = data

      // console.log("ORIGINAL ", state.editedItem, " ALTERADO ", state.editedItemEditableClone)
    },
    setNewTopic(state){
      const cloneLen = state.editedItemEditableClone.topics.length
      const originalLen =state.editedItem.topics.length
      
      var data = {}
      if(cloneLen + 1 > originalLen){
        data = {
          description: "--",
          id: undefined, //"c37d13cc-71c0-4f2a-a84c-8c99068bb981",
          session: undefined, //"39f61413-112c-45d3-8c17-b473431cebe9",
          title: "---",
          topic_category: "67f7fd43-357c-42f9-9ccc-be2a44cc8f69",
          topic_category_details: {
            color: "#EBFE22FF",
            id: "67f7fd43-357c-42f9-9ccc-be2a44cc8f69",
            name: "Categoria"
          }
        }
      }
      else
        data = state.editedItem.topics[state.editedItemEditableClone.topics.length]
      state.editedItemEditableClone.topics.push(data)
      state.topicLen = Math.ceil(state.editedItemEditableClone.topics.length/2)
    },

    rmvTopic(state){
      const cloneLen = state.editedItemEditableClone.topics.length
      if (cloneLen <= 1)
        return
      state.editedItemEditableClone.topics.pop()
      state.topicLen = Math.ceil(state.editedItemEditableClone.topics.length/2)
    },  
    setDate(state, date){
      state.editedItemEditableClone.session_date = date
    },
    setPatient(state, data){
      state.editedItemEditableClone.patient = data.patient.id
      state.editedItemEditableClone.patient_name = data.patient.name
    },
    setIndex(state, index){
      state.editedIndex = index
    },
    setLoading(state, status){
      state.loading = status
    }
  }

  const actions = {
    async getPatientsDashboard({state, commit, rootState}){   
      await axios.get(
        `/sessions/patients-dashboard/`) // ?page=${targetPage}${qs}`)
      .then(res => {
        commit('setPatients', res.data.results)
      })
      .catch(err => {
        if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
    },
    async getPatients({state, commit, rootState}){
      await axios.get(
        `/sessions/patients/`) // ?page=${targetPage}${qs}`)
      .then(res => {
        commit('setPatients', res.data.results)
      })
      .catch(err => {
        if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
    },
    
    async getSessions({state, commit, rootState}, payload){
      var targetPage = 1
      var qs =''
      
      if (payload){
        targetPage = payload.targetPage
        qs = payload.qs
      }
   
      await axios.get(
        `/sessions/sessions/?page=${targetPage}${qs}`)
      .then(res => {
        commit('setSessions', res.data.results)
        commit('setTotalSessionsDb', res.data && res.data.count ? res.data.count : 0)
      })
      .catch(err => {
        if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
    },
    
    async getCategories({state, commit, rootState}, payload){
      await axios.get(
        `/sessions/topics/categories-dashboard/`) //?page=${targetPage}${qs}`)
      .then(res => {
        commit('setCategories', res.data.results)
      })
      .catch(err => {
        if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
    },

    async getDictionary({state, commit, rootState}, payload){
      await axios.get(
        `/sessions/words-map/all/`)
      .then(res => {
        commit('setDict', res.data)
      })
      .catch(err => {
        if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
    },

    async saveSessionByTopic({state, commit, dispatch}){ // util no dashboard numa filtragem por categorias, p achar a sessao a ser salva baseando-se no id do topico clicado
      
    },

  
    async saveSession ({state, commit, dispatch}, payload){
      commit('setLoading', true)
      var setIt = true // default. so mudarah e sera diferente se tiver payload (ou seja, for um save parcial vindo do dashboard)
      if (!payload)
        var data = {id: state.editedItemEditableClone.id, item:  state.editedItemEditableClone}
      else {
        var data = {id: payload.id, item:  payload}
        setIt = false
      }
      await axios.patch(
        `/sessions/sessions/update/${data.item.id}/`, data.item)
      .then(res => {
        const payload = {editedIndex: state.editedItem, editedItem:res.data}
        if (setIt) // qd falso evita que o dashboard exiba so o item alterado apos salvar parcial
          dispatch('setItem', payload)
      })
      .catch(err => {
        if(err.response) {// client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
          commit('setSnackBarDesiredStatus', true)
        }
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
      commit('setLoading', false)
    },

    async createSession ({state, commit, dispatch}, data){
      commit('setLoading', true)
      var data = {id: state.editedItemEditableClone.id, item:  state.editedItemEditableClone }

      await axios.post(
        `/sessions/sessions/create/`, data.item)
      .then(res => {
        const payload = {editedIndex: state.editedItem, editedItem:res.data}
        dispatch('setItem', payload)
      })
      .catch(err => {
        if(err.response) {// client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
          commit('setSnackBarDesiredStatus', true)
        }
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
      commit('setLoading', false)
    },

    setItem({state, commit, rootState}, payload){
      const index = payload.editedIndex
      const item = payload.editedItem
      commit('setItem', item)
      commit('setIndex', index)
      commit('setSession', payload)      
    },
    setDefaultItem({state, commit}){
      commit('setItem', {
        id: '',
        patient: '',
        patient_name: '',
        session_date: '',
        topics: []
      })
      commit('setIndex', -1)
    },
    setEditableItem({state, commit, rootState}, payload){
      commit('setEditableItemClone', payload)
    },
    setEditableItemTopic({state, commit, rootState}, payload){
      payload.topic_category_details.name = payload.topic_category_details.topicName
      commit('setTopic', payload)
    },
    setEditableItemNewTopic({state, commit, rootState}){
      commit('setNewTopic')
    },
    rmvEditableItemTopic({state, commit, rootState}){
      commit('rmvTopic')
    },
    setDialogStatus({commit}, status){
      commit('setDialogStatus', status)
    },
    setDate({commit}, date){
      commit('setDate', date)
    },
    setPatient({commit}, data){
      commit('setPatient', data)
    }
  }

  const getters =  {
    editedItem (state){
      return state.editedItem
    },
    editedIndex (state){
      return state.editedIndex
    },
    sessions (state){
      return state.sessions
    },
    patients (state){
      return state.patients
    },
    categories (state){
      return state.categories
    },
    dictionary (state){
      return state.dictionary
    },
    topics (state){
      return state.editedItem.topics//state.topics
    },
    desiredRows (state){
      return Math.ceil(state.editedItem.topics.length/2)
    },
    desiredRows2 (state){
      return state.editedItemEditableClone.topics.length
    },
    editedItemEditableClone (state){
      return state.editedItemEditableClone
    },
    dialogStatus (state){
      return state.dialogStatus
    },
    sessionDate (state){
      return state.sessions[state.editedIndex].session_date
    },
    loading (state){
      return state.loading
    },
    countSession (state){
      return state.countSession
    },
    topicLen(state){
      return state.topicLen
    },
    totalSessionsDb(state){
      return state.totalSessionsDb
    },
    sessionSnackBar(state){
      return state.sessionSnackBarHandler
    }
  }

  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
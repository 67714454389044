<template>
  <transvision-table
    :title="title"
    />
</template>

<script>
  import axios from 'axios'

  import TransvisionTable from '../components/transvisions/TransvisionTable.vue'
  
  //axios.defaults.baseURL = 'http://localhost:8000/'

  export default {
    name: 'Transvisions',

    components: {
      TransvisionTable,
    },

    data: () => ({
      title: 'Transvisões',
    }),
  }

</script>


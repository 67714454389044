// n vo uar pq to sem tempo e isso dá problemas:
// try catch no vuex so funcionaria se eu retornasse promises aki
// ma n lembro como faze isso direito
import Vue from 'vue'
import Vuex from 'vuex'
import sessions from './modules/sessions'
import dashboard from './modules/dashboard'
import auth from './modules/auth'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    sessions,
    dashboard,
    auth
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
})

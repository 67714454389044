<template>
  <div>
    <v-toolbar
        dark
        color="primary"
      >
      <v-tooltip  v-if="$route.name == dashBoardRoute"  bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon  v-on="on"
         
          @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <span>Menu</span>
      </v-tooltip>
      
      <v-tooltip v-else bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
           </template>
      <span>Fechar</span>
      </v-tooltip>

      <v-toolbar-title>{{title}}</v-toolbar-title>

      <v-toolbar-items>
        <slot name="items"></slot>
      </v-toolbar-items>
    
      <v-spacer></v-spacer>

      <div v-if="topicsHandler && $route.name != 'Dashboard'" class="d-flex ">
        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" fab depressed small color="warning" class="mx-1" @click="rmvTopic">
            <v-icon small >mdi-minus-thick</v-icon>
          </v-btn>
        </template>
        <span>Clique aqui para remover um tópico.</span>
        </v-tooltip>

        <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" fab depressed  small color="warning" class="mx-1" @click="addTopic">
            <v-icon small >mdi-plus-thick</v-icon>
          </v-btn>
        </template>
        <span>Clique aqui para adicionar um tópico.</span>
        </v-tooltip>
      </div>
      
      <v-toolbar-items>
        <v-btn v-if="$route.name != dashBoardRoute" 
          :disabled="checkInput()"
          dark
          text
          @click="saveSession"
        >
          {{saveTitle}}
        </v-btn>
      </v-toolbar-items>

    </v-toolbar>
    
    <v-navigation-drawer
        v-model="drawer"
        absolute
        bottom
        temporary
      >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item to="/patients" class="tile">
              <v-list-item-icon>
                <v-icon color="primary">mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Pacientes</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

           <v-list-item to="/categories" class="tile">
              <v-list-item-icon>
                <v-icon color="primary">mdi-tag-text-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Categorias</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

           <v-list-item to="/dicts" class="tile">
              <v-list-item-icon>
                <v-icon color="primary">mdi-book-open-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dicionário</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

          <v-list-item to="/sessions" class="tile">
              <v-list-item-icon>
                <v-icon color="primary">mdi-clipboard-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sessões</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

          <v-list-item to="/" class="tile">
              <v-list-item-icon>
                <v-icon color="primary">mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

          <v-list-item to="/transvisions" class="tile">
              <v-list-item-icon>
                <v-icon color="primary">mdi-eye-settings-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Transvisões</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

          <v-spacer></v-spacer>
          
          <v-list-item @click="logout" class="tile">
              <v-list-item-icon>
                <v-icon color="primary">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
          </v-list-item>

       
        </v-list-item-group>
      </v-list>
      
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn color="primary" block>
            <v-icon class="mx-2"
              medium
              >
              mdi-logout
            </v-icon>
            Logout
          </v-btn>
        </div>
      </template> -->

    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: 'Session Toolbar'
    },
    topicsHandler: {
      type: Boolean,
      default: true
    },
    saveTitle: {
      type: String,
      default: 'Salvar Sessão'
    }
  },

  components: {
  },

  data: () => ({
    drawer: false,
    group: null,
    dashBoardRoute: 'Dashboard'
  }),

  computed: {
    ...mapGetters('sessions', {
      editedItem: 'editedItem',
      editedIndex: 'editedIndex',
      editedItemEditableClone: 'editedItemEditableClone',
      // sessions: 'sessions',
    }),
    
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
    
    checkInput(){ //disabled=checkInput
      if(this.editedItemEditableClone.session_date && this.editedItemEditableClone.patient_name)
        return false // habilite, tem valor setado l
      else
        return true // desabilita, n tem valor setado
    },

    async saveSession(){
      if (this.editedIndex != -1)
        await this.$store.dispatch('sessions/saveSession')
      else
        await this.$store.dispatch('sessions/createSession')
      this.close()
    },
    
    async close (){
      await this.$store.dispatch('sessions/setDefaultItem')
      await this.$store.dispatch('sessions/setPatient', {
        patient: {id: '', name: ''}
      })
      this.$store.dispatch('sessions/setDialogStatus', false)
      this.$store.commit('sessions/setSnackBarStatus')
    },

    addTopic (){
      this.$store.dispatch('sessions/setEditableItemNewTopic')
    },

    rmvTopic (){
      if (this.rows == 1)
        return
            this.$store.dispatch('sessions/rmvEditableItemTopic')
    },
  }
}
</script>

<style>
  .tile:hover {
    background: #C5CAE9;
  }
</style>
<template>
  <v-container class="container py-2">
    <div class="text-center">
      <v-snackbar
        :value="sessionSnackBar.status"
        :timeout="snackTimeout"
        top
        color="error"
      >
        <h3 class="d-flex justify-center">{{snackMsg}}</h3>
      </v-snackbar>
    </div>
    <v-data-table
      height="68vh"
      :headers="headers"
      :items="sessions"
      :loading="loading"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 dictionary-maps-table"
    >

      <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
        <v-tooltip bottom :key="header.value" >
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ header.text }}</span>
          </template>
          <span v-if="header.value != 'actions'">ordenar por {{ header.text.toLowerCase() }} 
            em ordem de criação, ordem ascendente ou ordem descendente, respectivamente</span>
          <span v-else>Ações</span>
        </v-tooltip>
      </template>
      
      <template v-slot:top>
        <v-toolbar
          flat
        >
        
          <v-icon class="mx-4"
            large
            color="primary"
          >
            mdi-clipboard-multiple-outline
          </v-icon>
          <v-toolbar-title > Sessões </v-toolbar-title>
          <v-divider 
            class="mx-4"
            inset
            vertical
          ></v-divider>

           <v-row no-gutters>
            <v-col cols=11>
              <v-text-field
                v-model.trim="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols=1>
                <v-tooltip bottom class="veryLongTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  @click="handleSearch(-1)"
                  class="mx-2"
                  fab
                  small
                  depressed
                  color="primary"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>
                <span>Buscar sessões por data ou paciente.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
            <session-dialog 
            />
          <v-btn @click="setDefaultItem" color="primary">Nova Sessão</v-btn>
          
          <v-dialog 
            v-model="dialogDelete" 
            max-width="500px"
            persistent>
            <sessions-delete-form
              :targetId="editedItem.id"
              :tgtSession="editedItem.session_date"
              :tgtPatientName="editedItem.patient_name"
              @close="closeDelete"
              @deleted="deleteItemConfirm"/>
          </v-dialog> 
        </v-toolbar>

       
      </template>
      <template v-slot:item.actions="{ item }">
        
       <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              class="mr-2"
              @click="editItem(item)"
              medium
              color="#FCB700"
            >
              mdi-pencil
            </v-icon>
          </template>
        <span>Editar sessão</span>
        </v-tooltip>
         <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            @click="deleteItem(item)"
            medium
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
      <span>Excluir sessão</span>
      </v-tooltip>
      </template>

      <!-- qd nao tiver item cadastrado -->
      <template v-slot:no-data>
        <p class="font-weight-black indigo--text">
          Nenhuma sessão encontrada
        </p>
      </template>
      <template v-slot:footer>
        <div class="text-center">
          <span class="text-center"> total de sessões encontradas: {{showTotalSessions}} </span>
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="8"
            @input="getDesiredPage"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import SessionDialog from '../components/sessions/SessionDialog'
  import { mapGetters, mapState } from 'vuex'
  import sessionsDeleteForm from '../components/sessions/sessionsDeleteForm'

  //axios.defaults.baseURL = 'http://localhost:8000/'
  
  export default {
    name: 'Sessions',
    
    components: {
      SessionDialog,
      sessionsDeleteForm
    },

    mounted () {
      this.getSessions(this.currentPage)
      this.getPatients()
    },

    computed: {
      ...mapState(['sessionSnackBar']),
      ...mapGetters('sessions', {
        editedItem: 'editedItem',
        editedIndex: 'editedIndex',
        sessions: 'sessions',
        totalSessionsDb: 'totalSessionsDb',
        sessionSnackBar: 'sessionSnackBar'
      }),
      ...mapGetters('dashboard', {
        zebraMode: 'zebraMode'
      }),
      showTotalSessions (){
        return this.totalSessions
      },
      totalPages (){

        var ammount = Math.ceil(this.totalSessions / this.itemsPerPage)
        return (isNaN(ammount) || ammount == 0 ? 1 : ammount)
      }
    },
    data: () => ({
      initialTopicsAmmount: 10,
      loading: true,
      headers: [
        { text: 'Dia da semana', value: 'week_day', class: 'white--text' },
        { text: 'Data', value: 'session_date', class: 'white--text' },
        { text: 'Paciente', value: 'patient_name', class: 'white--text' },
        { text: 'Ações', value: 'actions', sortable: false, class: 'white--text' },
      ],
      search: '',
      totalSessions: 0,
      currentPage: 1,
      pageControler: 1, // evitar que chame a api qd chamar uma mesma pagina
      itemsPerPage: 8, // por de acordo com oq eu definir no backend
      item: {},
      dialogDelete: false,
      //editedIndex: -1,
      //editedItem: {},
      //snack
      snackMsg: "Já existe uma sessão para este paciente nesta data.",
      snackTimeout: 2200

    }),

    methods: {
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      close(){
        this.$store.dispatch('sessions/setDialogStatus', false)
      },

      getDesiredPage(v){ //v == this.currentPage por conta do v-model
        if (this.pageControler != v){
          //this.getPatients(v)
          this.handleSearch(v)
          this.pageControler = this.currentPage
        }
      },

      handleSearch(page = -1){
        if (page == -1){
          this.currentPage = 1
          this.pageControler = 1
          page = 1 // pra buscar primeira do filter query
        }
        if (this.search){
          this.getSessions(page, `&search=${this.search}`)
        }
        else{
          this.getSessions(page)
        }
      },

      async deleteItem (item) {
        const editedIndex = this.sessions.indexOf(item)
        const data = {editedItem: item, editedIndex: editedIndex}
        await this.$store.dispatch('sessions/setItem', data)
        await this.$store.dispatch('sessions/setEditableItem', data)
        
        //this.editedIndex = this.sessions.indexOf(item)
        //this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        const sessionId = this.editedItem.id
        const tgtId= this.sessions.map(x => {
            return x.id;
          }).indexOf(sessionId);    
        this.$store.commit('sessions/removeSession', tgtId)
        this.totalSessions -=1
        this.closeDelete()
        this.setDefaultAfterDelete()
      },
      async setDefaultAfterDelete(){
         const data = {
          editedItem: {
            id: '',
            patient: '',
            patient_name: '',
            session_date: '',
            topics: []
          }
        }
        
        await this.$store.dispatch('sessions/setDefaultItem')
        // await this.$store.dispatch('sessions/setEditableItem', data)
        
        // for (let i = 0; i < this.initialTopicsAmmount; i++) {
        //   this.$store.dispatch('sessions/setEditableItemNewTopic')
        // }

      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          //this.editedItem = Object.assign({}, this.defaultItem)
         // this.editedIndex = -1
        })
      },
      
      async setDefaultItem(){
        const data = {
          editedItem: {
            id: '',
            patient: '',
            patient_name: '',
            session_date: '',
            topics: []
          }
        }
        
        await this.$store.dispatch('sessions/setDefaultItem')
        await this.$store.dispatch('sessions/setEditableItem', data)
        
        for (let i = 0; i < this.initialTopicsAmmount; i++) {
          this.$store.dispatch('sessions/setEditableItemNewTopic')
        }

        this.open()
      },

      open(){
        this.$store.dispatch('sessions/setDialogStatus', true)
        this.$store.commit('sessions/setSnackBarStatus')
      },

      async getPatients (targetPage, qs = '') {
        this.loading = true
        await this.$store.dispatch('sessions/getPatientsDashboard') //getPatients')
        // this.tableItems = this.sessions
        this.loading = false
      },

      async getSessions (targetPage = 1, qs = '') {
        this.loading = true
        const payload = {
          targetPage: targetPage,
          qs: qs
        }
        await this.$store.dispatch('sessions/getSessions', payload)
        this.totalSessions = this.totalSessionsDb
        this.loading = false
      },

      async editItem (item) {
        const editedIndex = this.sessions.indexOf(item)
        const data = {editedItem: item, editedIndex: editedIndex}
        await this.$store.dispatch('sessions/setItem', data)
        await this.$store.dispatch('sessions/setEditableItem', data)
        this.open()
      }
    }
  }
</script>

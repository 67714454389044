import axios from 'axios'

const isProd = false
if (isProd)
  axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT // || 
else{
  axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT //'http://localhost:8000'
}
 
axios.defaults.withCredentials = true
const refreshUrl = '/users/dj-rest-auth/token/refresh/'
const loginUrl = '/users/dj-rest-auth/login/'

async function login(user) { 
  return await axios({url: '/users/dj-rest-auth/login/', data: user, method: 'POST', timeout: 10000})
}

async function logout() {
  return await axios({url: '/users/dj-rest-auth/logout/', data: {}, method: 'POST'})
}

async function getUser(){
  return await axios({url: '/users/dj-rest-auth/user/', data: {}, method: 'GET'})
}

async function refresh(){
  return await axios({url: '/users/dj-rest-auth/token/refresh/', data: {}, method: 'POST'})
}

async function recover(email) { 
  return await axios({url: '/users/recover/', data: email, method: 'POST'})
}

async function changePassword(user){
  return await axios({url: `/users/change-password/${user.token}/`, data: user.data, method: 'PUT'})
}

async function createTransvision(transvision){
  return await axios({url: `/sessions/transvisions/create/`, data: transvision, method: 'POST'})
}

async function updateTransvision(transvision){
  //console.log("Upate ", transvision)
  return await axios({url: `/sessions/transvisions/update/${transvision.id}/`, data: transvision, method: 'PUT'})
}

async function removeTransvision(transvisionId){
  return await axios({url: `/sessions/transvisions/delete/${transvisionId}/`, method: 'DELETE'})
}

async function listTransvisions(data = {'page': 1, 'qs': ''}){
  return await axios({url: `/sessions/transvisions/?page=${data.page}&qs=${data.qs}`, method: 'GET'})
}

async function getPatient(pk){
  return await axios({url: `/sessions/patients/${pk}/`, method: 'GET' })
}

async function generateDoc(data){
  return await axios({url: `sessions/transvisions/generate-sheet/`, data: data, method: 'POST', responseType: 'blob' })
}

async function transvisionHistory(user_pk){
  return await axios({url: `sessions/patients/transvisions-info/${user_pk}/`, method: 'GET'})
}

export { 
  login,
  logout,
  getUser,
  refresh,
  recover,
  changePassword,
  createTransvision,
  updateTransvision,
  removeTransvision,
  listTransvisions,
  getPatient,
  generateDoc,
  transvisionHistory
}

<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation>
    <v-card>
      <v-card-title class="text-h6 white--text indigo">
       {{title}}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col 
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                autofocus
                v-model="finalEditedItem.name"
                label="Nome"
                maxlength="150"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                autofocus
                v-model="finalEditedItem.email"
                label="E-mail"
                @input="v => {finalEditedItem.email = v.toLowerCase().replace(/ /g, '')}"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="finalEditedItem.phone"
                label="Telefone"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="finalEditedItem.cpf"
                :rules="cpfRules"
                label="Cpf"
                maxlength="11"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4">
              <birth-day
                :date="finalEditedItem.birth_date"
                @date-changed="setDate"/>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="finalEditedItem.gender"
                :items="genderOptions"
                item-text="gender"
                item-value="abbr"
                label="Gênero"
                hint="Gênero"
                persistent-hint
                single-line
                return-object
              ></v-select>
            </v-col>
            <v-col cols=12>
              <p class="text-uppercase font-weight-black text-left">
                Informações de endereço:
              </p>
              <v-progress-linear v-if="addrLoading"
                indeterminate
                class="mb-4"
              ></v-progress-linear>
            </v-col>
            <v-col 
              cols="12"
              sm="6"
              md="2"
            >
              <v-text-field
                v-model="finalEditedItem.zip_code"
                label="Cep"
                placeholder="22261020"
                maxlength="8"
                @blur="sugestAddress"
              ></v-text-field>
            </v-col>
            <v-col 
              cols="12"
              sm="6"
              md="2"
            >
              <v-text-field
                v-model="finalEditedItem.city"
                label="Cidade"
              ></v-text-field>
            </v-col>
            <v-col 
              cols="12"
              sm="6"
              md="2"
            >
              <v-select
                v-model="finalEditedItem.region"
                :items="UfOptions"
                hint="Estado"
                persistent-hint
                label="Estado"
                single-line
              ></v-select>
            </v-col>
            <v-col 
              cols="12"
              sm="6"
              md="2"
            >
              <v-text-field
                v-model="finalEditedItem.district"
                label="Bairro"
              ></v-text-field>
            </v-col>
            <v-col 
              cols="12"
              sm="6"
              md="4"
            >
              <v-text-field
                v-model="finalEditedItem.street"
                label="Rua"
              ></v-text-field>
            </v-col>
            <v-col 
              cols="12"
              sm="6"
              md="2"
            >
              <v-text-field
                v-model="finalEditedItem.number"
                label="Número"
              ></v-text-field>
            </v-col>
            <v-col 
              cols="12"
              sm="12"
              md="10"
            >
              <v-text-field
                v-model="finalEditedItem.complement"
                label="Complemento"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions> 
        <v-row no-gutters>
          <v-col cols=12>
            <span class="subtitle-2 font-weight-medium red--text">{{ msg }}</span>
          </v-col>
          <v-col cols=12>
            <v-progress-linear v-if="loading"
              indeterminate
              class="mb-4"
            ></v-progress-linear>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right" cols=12>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ma-1"
              white--text
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="ma-1"
              :disabled="!valid"
              white--text
              @click="saveValidatedData"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios'
//axios.defaults.baseURL = 'http://localhost:8000/'

import BirthDay from './BirthDay'

export default {
  components: {
    BirthDay
  },

  props: {
    sourceEditedIndex: {
      type: Number,
      default: -1,
    },
    sourceEditedItem: {
      type: Object,
      default: { 
        id: "", 
        name: "", 
        email: "", 
        phone: "--", 
        cpf: "--",
        birth_date: "",
        gender: {gender: "Masculino", abbr: "M"},
        gender_display: "Masculino",
        region: "--",
        city: "--",
        district: "--",
        street: "--",
        number: "--",
        zip_code: "--",
        complement: "--"
      }
    },
  },

  data: function() {
    return {
      valid: true,
      loading: false,
      addrLoading: false,
      msg: "",
      // editedIndex: this.sourceEditedIndex,
      editedItem: this.sourceEditedItem, // this.formatAddressForShow(),//this.sourceEditedItem,
      genderOptions: [
        { gender: 'Masculino', abbr: 'M' },
        { gender: 'Feminino', abbr: 'F' },
        { gender: 'Outro', abbr: 'O' },
      ],
      UfOptions: [
        'AC', 'AL', 'AP', 'AM',	
        'BA', 'CE', 'DF', 'ES',	
        'GO', 'MA', 'MT', 'MS',	
        'MG', 'PA', 'PB', 'PR',
        'PE',	'PI', 'RJ', 'RN',
        'RS', 'RO', 'RR', 'SC',	
        'SP', 'SE'	
      ],
      nameRules: [
        v => !!v || 'O nome é obrigatório',
      ],
      emailRules: [ // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v)
        v => !!v || 'O E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'Digite um E-mail válido',
      ],
      cpfRules: [
        v => ((v && v.length == 11 || (v == '--' || v == '-'))) || "Um cpf precisa ter 11 dígitos"
      ]
    }
  },

  computed: { 
    finalEditedItem: {
      get: function (){
        this.editedItem = this.sourceEditedItem
        return this.editedItem
      },
    },

    title: function (){
      return this.sourceEditedIndex > -1 ? 'Editando Paciente' : 'Novo Paciente'
    }
  },

  methods: {
    formatSuggestedAddress(data){
      this.editedItem.district = data.bairro ? data.bairro : this.editedItem.district
      this.editedItem.street = data.logradouro ?  data.logradouro: this.editedItem.street
      this.editedItem.city = data.localidade ? data.localidade : this.editedItem.city
      this.editedItem.region = data.uf ? data.uf : this.editedItem.region
      this.editedItem.complement = data.complemento ? data.complemento : this.editedItem.complement
    },

    async sugestAddress (e){
      const cep = parseInt(e.target.value)//.replace('-'), '')
      
      if (isNaN(cep) == false){ // cep possivelmente valido
        this.addrLoading = true
        await axios.get(
          `/sessions/patients/address-sugestion/?cep=${cep}`)
        .then(res => {
          if(res.data && res.data.length > 0){
            this.formatSuggestedAddress(res.data[0])
          }
        })
        .catch(err => {
          if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
          }
          else if (err.request) { // response nunca recebida
            console.log("error: never received a response: ")
          }
          else {
            console.log("error: ")
          }
        })
        this.addrLoading = false
      }
    },

    validate () {
      this.$refs.form.validate()
    },

    setDate(date){
      this.finalEditedItem.birth_date = date
    },

    close () {
      this.$emit('close')
      this.resetMsg()
    },

    resetMsg (){
      this.msg = ""
    },

    formatAddressForSave(){
      var address = {}
      const addr_keys = [ 'city', 'complement', 'district', 'number', 'region', 'street', 'zip_code']
          
      for (var key in this.finalEditedItem)
        if (addr_keys.includes(key)){
          address[key] = this.finalEditedItem[key]
          delete this.finalEditedItem[key]
        }
      this.finalEditedItem.address = address
    },

    formatGender(){
      if (typeof(this.finalEditedItem.gender) === 'object'){ // curiosidade: typeof(null) é object também
        const {gender, abbr} = this.finalEditedItem.gender
        this.finalEditedItem.gender_display = gender
        this.finalEditedItem.gender = abbr
      }
    },

    async saveValidatedData () {
      this.loading = true
      this.formatAddressForSave()
      this.formatGender()
      
      if (this.sourceEditedIndex > -1) { // EDITING  
        const pk = this.finalEditedItem.id

        await axios.patch(`/sessions/patients/update/${pk}/`, this.finalEditedItem)
          .then(res => {
            const data = this.finalEditedItem
            console.log(" updated successfully: ")
            this.resetMsg()
            this.$emit('save', data)
          })
          .catch(err => {
            if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
              console.log("error: bad response: ")
              if(err.response.data && err.response.data.email) {
                const email = this.finalEditedItem.email
                const msg = `Já existe um paciente com o E-mail ${email}. Por favor, escolha outro E-mail e tente novamente.`
                this.msg = msg
                this.$emit('resend-data', this.finalEditedItem)
              }
            }
            else if (err.request) { // response nunca recebida
              console.log("error: never received a response: ")
            }
            else {
              console.log("error: ")
            }
          })
        
      } else { // CREATING
        await axios.post(`/sessions/patients/create/`, this.finalEditedItem)
        .then(res => {
          console.log(" created successfully: ")
          this.resetMsg()
          this.$emit('save', res.data)
        })
        .catch(err => {
          if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
            if(err.response.data && err.response.data.email) {
              const email = this.finalEditedItem.email
              const msg = `Já existe um paciente com o E-mail ${email}. Por favor, escolha outro E-mail e tente novamente.`
              this.msg = msg
              this.$emit('resend-data', this.finalEditedItem)
            }
          }
          else if (err.request) { // response nunca recebida
            console.log("error: never received a response: ")
          }
          else {
            console.log("error: ")
          }
        })
      }
      this.loading = false
    },  
  }
}
</script>
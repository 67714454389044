<template>

  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation>
    <v-card>
      <v-card-title class="text-h6 white--text indigo">
       {{title}}
        <v-spacer></v-spacer>
        <v-btn  @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
        <!-- <span v-if="pdfLoading"> Gerando pdf, por favor, aguarde... </span> -->
      <v-card-text>
        
        <v-container>
          <v-row ref="facture">
            <v-col 
              cols="12"
              sm="6"
              md="6"
            >
            <div v-if="sourceEditedIndex > -1 ">
               <v-text-field
                :value="editedItem.patient_name"
                label="Paciente"
                maxlength="150"
                filled
                outlined
                disabled
              ></v-text-field>
            </div>
               <v-autocomplete v-else
                hide-details
                v-model="patient"
                :disabled="check"
                outlined
                :rounded="true"
                placeholder="Paciente"
                dense
                height="30"
                
                :items="patients.map(({ name }) => name)"
                return-object 
                @change="autoCompletePatientData(
                  patients
                  .filter(it => it.name == patient))">
              </v-autocomplete>
            </v-col>
            <v-col 
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="finalEditedItem.identidade"
                label="Identidade (nome fictício)"
                maxlength="150"
                filled
                outlined
                required
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <date-picker @set-date="setDate" :date="finalEditedItem.data_tv"/>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="finalEditedItem.numero_transvisao"
                label="Número da transvisão"
                filled
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="finalEditedItem.intervalo_ultima_transvisao"
                
                label="Intervalo entre as transvisões"
                maxlength="30"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="finalEditedItem.terapeuta"
                
                label="Terapeuta"
                maxlength="50"
                filled
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.genero"
                :items="genderOptions"
                item-text="gender"
                item-value="abbr"
                label="Gênero"
                hint="Gênero"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="finalEditedItem.idade"
                label="Idade"
                maxlength="2"
                filled
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="finalEditedItem.setenio"
                label="Setênio"
                maxlength="20"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.estado_civil"
                :items="CivilStateOptions"
                item-text="civilState"
                item-value="abbr"
                label="Estado Civil"
                hint="Estado Civil"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="finalEditedItem.casametos"
                label="Casamento"
                maxlength="11"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.domicilio"
                :items="DomicileOptions"
                item-text="domicile"
                item-value="abbr"
                label="Domicílio"
                hint="Domicílio"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.profissao_trabalho"
                :items="ProfissionOptions"
                item-text="profission"
                item-value="abbr"
                label="Profissão - trabalho"
                hint="Profissão - trabalho"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.profissao_financeiro"
                :items="RemunerationOptions"
                item-text="remuneration"
                item-value="abbr"
                label="Profissão - financeiro"
                hint="Profissão - financeiro"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-col v-if="finalEditedItem.domicilio == 'AC' || finalEditedItem.domicilio.abbr == 'AC'"
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="finalEditedItem.acompanhado_com"
                label="Acompanhado com:"
                maxlength="20"
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-textarea
                v-model="finalEditedItem.familia_de_origem"
                label="Família de origem"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-textarea
                v-model="finalEditedItem.familia_de_origem_mortos"
                label="Mortos (família de origem)"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-textarea
                v-model="finalEditedItem.famila_descendente"
                label="Família descendente"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-textarea
                v-model="finalEditedItem.familia_descendente_mortos"
                label="Mortos (família descendente)"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.motivo_busca_terapia"
                label="Queixa ou motivo da busca da terapia"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.terapias_anteriores"
                label="Terapias anteriores (quais e duração)"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.terapias_atual"
                label="Terapias atual (duração, frequência...)"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.condicao_saude_doenca_psiquica"
                label="Condição de saúde: doença psíquica"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.condicao_saude_doenca_importante"
                label="Condição de saúde: doença importante"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.condicao_saude_tratamento"
                label="Condição de saúde: tratamento"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols=12>
              <p class="text-uppercase font-weight-black text-left">
                Dinamismos:
              </p>
              <p class="text-uppercase font-weight-black text-center">
                Passado:
              </p>
              <v-divider></v-divider>
            </v-col>
            
            <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DM <p class="lower">v4</p>
              </p>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DP-1 <p class="lower">v2v1a</p>
              </p>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DP-2  <p class="lower">v2v4</p>
              </p>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DF <p class="lower">v3</p>
              </p>
            </v-col>

             <!-- M -->
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dm_passado_m"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="M"
                hint="M"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp1_passado_m"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="M"
                hint="M"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp2_passado_m"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="M"
                hint="M"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.df_passado"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="DF"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            
            <!--  P -->
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dm_passado_p"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="P"
                hint="P"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
             <v-divider
              vertical
            ></v-divider>
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp1_passado_p"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="P"
                hint="P"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp2_passado_p"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="P"
                hint="P"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
           
            <!-- PRESENTE -->
            <v-col cols=12>
              <p class="mt-2 text-uppercase font-weight-black text-center">
                Presente:
              </p>
              <v-divider></v-divider>
            </v-col>
             <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DM <p class="lower">v4</p>
              </p>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DP-1 <p class="lower">v2v1a</p>
              </p>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DP-2  <p class="lower">v2v4</p>
              </p>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="3"
              md="3">
              <p class="text-uppercase font-weight-black text-center">
                DF <p class="lower">v3</p>
              </p>
            </v-col>

             <!-- M -->
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dm_presente_m"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="M"
                hint="M"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp1_presente_m"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="M"
                hint="M"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp2_presente_m"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="M"
                hint="M"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.df_presente"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="DF"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            
            <!--  P -->
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dm_presente_p"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="P"
                hint="P"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
             <v-divider
              vertical
            ></v-divider>
             <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp1_presente_p"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="P"
                hint="P"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-divider
              vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="finalEditedItem.dp2_presente_p"
                :items="DinamismoOptions"
                item-text="dinamismo"
                item-value="abbr"
                label="P"
                hint="P"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
              <!-- ESSENCIAL PRA MANTER A APARENCIA LEGAL -->
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
              >
              <!-- ESSENCIAL PRA MANTER A APARENCIA LEGAL -->
              </v-col>
              
            </v-row>
            <v-row>
               <v-col
              cols="12"
              sm="6"
              md="6">
              <p class="text-uppercase font-weight-black text-center">
                DA-1
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6">
              <p class="text-uppercase font-weight-black text-center">
                DA-2
              </p>
            </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <v-select
                  v-model="finalEditedItem.da1_presente"
                  :items="DinamismoAOptions"
                  item-text="dinamismoA"
                  item-value="abbr"
                  label="DA-1"
                  persistent-hint
                  single-line
                  return-object
                  outlined
                ></v-select>
              </v-col>
              
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <v-select
                  v-model="finalEditedItem.da2_presente"
                  :items="DinamismoAOptions"
                  item-text="dinamismoA"
                  item-value="abbr"
                  label="DA-2"
                  persistent-hint
                  single-line
                  return-object
                  outlined
                  
                ></v-select>
              </v-col>
            </v-row>
            
            <!-- -->
            <v-col cols=12>
              <p class="text-uppercase font-weight-black text-center mt-2">
                Matrizes:
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="finalEditedItem.vla"
                :items="MatrizOptions"
                item-text="matriz"
                item-value="abbr"
                label="V1a"
                hint="v1a"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            <v-divider
                vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="finalEditedItem.v4"
                :items="MatrizOptions"
                item-text="matriz"
                item-value="abbr"
                label="V4"
                hint="v4"
                persistent-hint
                single-line
                return-object
                outlined
              ></v-select>
            </v-col>
             <v-divider
                vertical
            ></v-divider>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="finalEditedItem.v5"
                :items="MatrizOptions"
                item-text="matriz"
                item-value="abbr"
                label="V5"
                hint="v5"
                persistent-hint
                single-line
                return-object
                filled
                outlined
              ></v-select>
            </v-col>
            </v-row>
            <v-col cols=12>
              <p class="text-uppercase font-weight-black text-center">
                Áreas da personalidade:
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.familiar"
                label="Familiar"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.pessoal"
                label="Pessoal"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.social"
                label="Social"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.profissional"
                label="Profissional"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="d-flex justify-center mt-2"
              >
                <v-textarea
                  v-model="finalEditedItem.espiritual"
                  label="Espiritual"
                  hide-details
                  text-narrow
                  name="input-7-1"
                  rows="4"
                  outlined
                ></v-textarea>
              </v-col>
            <v-col cols=12 class="mt-4">
              <v-divider></v-divider>
            </v-col>
           
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.dinamica_familiar"
                label="Dinâmica familiar (esboço)"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                outlined
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-textarea
                v-model="finalEditedItem.desafio"
                label="Desafio"
                hide-details
                text-narrow
                name="input-7-1"
                rows="4"
                filled
                outlined
              ></v-textarea>
            </v-col>
            <compreension title="Compreensões:" :sourceCompreentions="finalEditedItem.compreensoes"/>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions> 
        <v-row no-gutters>
          <v-col cols=12>
            <span class="subtitle-2 font-weight-medium red--text">{{ msg }}</span>
          </v-col>
          <v-col cols=12>
            <v-progress-linear v-if="loading"
              indeterminate
              class="mb-4"
            ></v-progress-linear>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right" cols=12>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ma-1"
              white--text
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="ma-1"
              :disabled="!editedItem.patient"
              white--text
              @click="saveValidatedData"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import datePicker from './DatePicker.vue'
import compreension from './Compreension.vue'
import { transvisionHistory, createTransvision, updateTransvision, removeTransvision, listTransvisions } from '../../api/index'

export default {
  components: {
    datePicker,
    compreension,
    
  },

  props: {
    sourceEditedIndex: {
      type: Number,
      default: -1,
    },
    
    sourceEditedItem: {
      type: Object,
      default () {
        return {
          id: "", 
          patient: "",//this.patient, 
          identidade: "--", 
          setenio: "",
          data_tv: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), 
          numero_transvisao: "--", // trocar para numero_transvisoes
          intervalo_ultima_sessao: "", // trocar para intervalo_ultima_transvisao
          terapeuta: "--",
          genero: {genero: "Masculino", abbr: "M"},
          gender_display: "Masculino",
          idade: "--",
          estado_civil: { civilState: 'Casado', abbr: 'C' },
          estado_civil_display: "Casado", 
          casamentos: "--",
          domicilio: { domicile: 'Só', abbr: 'SO' },
          domicilio_display: "Só",
          acompanhado_com: "--",
          profissao_trabalho: { profission: '0 = não', abbr: '0' },
          profissao_trabalho_display: "0 = não",
          profissao_financeiro: { remuneration: '0 = não remunerado', abbr: '0' },
          profissao_financeiro_display: '0 = não remunerado',
          dm_passado_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dm_passado_m_display: '1 = precário; ausente ou quase ausente',
          dm_passado_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dm_passado_p_display: '1 = precário; ausente ou quase ausente',
          dp1_passado_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp1_passado_m_display: '1 = precário; ausente ou quase ausente',
          dp1_passado_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp1_passado_p_display: '1 = precário; ausente ou quase ausente',
          dp2_passado_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp2_passado_m_display: '1 = precário; ausente ou quase ausente',
          dp2_passado_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp2_passado_p_display: '1 = precário; ausente ou quase ausente',
          df_passado: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          df_passado_display: '1 = precário; ausente ou quase ausente',
          dm_presente_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dm_presente_m_display: '1 = precário; ausente ou quase ausente',
          dm_presente_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dm_presente_p_display: '1 = precário; ausente ou quase ausente',
          dp1_presente_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp1_presente_m_display: '1 = precário; ausente ou quase ausente',
          dp1_presente_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp1_presente_p_display: '1 = precário; ausente ou quase ausente',
          dp2_presente_m: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp2_presente_m_display: '1 = precário; ausente ou quase ausente',
          dp2_presente_p: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          dp2_presente_p_display: '1 = precário; ausente ou quase ausente',
          df_presente: { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
          df_presente_display: '1 = precário; ausente ou quase ausente',
          da1_presente: { dinamismoA: '0 = precário; ausente ou quase ausente', abbr: '0' },
          da1_presente_display: '1 = precário; ausente ou quase ausente',
          da2_presente: { dinamismoA: '0 = precário; ausente ou quase ausente', abbr: '0' },
          da2_presente_display: '0 = precário; ausente ou quase ausente',
          vla: { matriz: 'M >', abbr: 'MG' },
          vla_display: 'M >',
          v4: { matriz: 'M >', abbr: 'MG' },
          v4_display: 'M >',
          v5: { matriz: 'M >', abbr: 'MG' },
          v5_display: 'M >',
          familia_de_origem: '',
          familia_de_origem_mortos: '',
          famila_descendente: '',
          familia_descendente_mortos: '',
          motivo_busca_terapia: '',
          terapias_anteriores: '',
          terapias_atual: '',
          condicao_saude_doenca_psiquica: '',
          condicao_saude_doenca_importante: '',
          condicao_saude_tratamento: '',
          familiar: '',
          pessoal: '',
          social: '',
          profissional: '',
          espiritual: '',
          dinamica_familiar: '',
          desafio: '',
          compreensoes: [{'description': 'babaT'},{'description': 'teoT'}]
        }
      }
    },
  },

  data: function() {
    return {
      valid: true,
      loading: false,
      addrLoading: false,
      pdfLoading: false,
      msg: "",
      patient: "",
      patients: [],
      // editedIndex: this.sourceEditedIndex,
      editedItem: this.sourceEditedItem, // this.formatAddressForShow(),//this.sourceEditedItem,
      genderOptions: [
        { gender: 'Masculino', abbr: 'M' },
        { gender: 'Feminino', abbr: 'F' },
        { gender: 'Outro', abbr: 'O' },
      ],
      CivilStateOptions: [
        { civilState: 'Casado', abbr: 'C' },
        { civilState: 'Solteiro', abbr: 'S' },
        { civilState: 'Outros', abbr: 'O' },
      ],
      DomicileOptions: [
        { domicile: 'Só', abbr: 'SO' },
        { domicile: 'Acompanhado', abbr: 'AC' },
        { domicile: 'Sustentado', abbr: 'SU' },
        { domicile: 'Alugado', abbr: 'AL' },
        { domicile: 'Próprio', abbr: 'PR' },
      ],
      ProfissionOptions: [
        { profission: '0 = não', abbr: '0' },
        { profission: '1 = junior, começando, tipo estágio', abbr: '1' },
        { profission: '2 = contratado, em começo', abbr: '2' },
        { profission: '3 = já é referencia no trabalho; coordena, dirige', abbr: '3' },
        { profission: '4 = sênior', abbr: '4' },
        { profission: '5 = outros', abbr: '5' },
      ],
      RemunerationOptions: [
        { remuneration: '0 = não remunerado', abbr: '0' },
        { remuneration: '1 = remuneração que não cobre os gastos pessoais', abbr: '1' },
        { remuneration: '2 = remuneração suficiente', abbr: '2' },
        { remuneration: '3 = remuneração sem sobra para pagar as despesas', abbr: '3' },
        { remuneration: '4 = remuneração com sobra depois de pagar as despesas', abbr: '4' },
        { remuneration: '5 = outros', abbr: '5' },
      ],
      DinamismoOptions: [
        { dinamismo: '1 = precário; ausente ou quase ausente', abbr: '1' },
        { dinamismo: '2 = regular; deficiente para menos', abbr: '2' },
        { dinamismo: '3 = bom; eficiente', abbr: '3' },
        { dinamismo: '4 = regular; deficiente para mais', abbr: '4' },
        { dinamismo: '5 = precário; excessivo ou quase excessivo', abbr: '5' },
      ],
      DinamismoAOptions: [
        { dinamismoA: '0 = precário; ausente ou quase ausente', abbr: '0' },
        { dinamismoA: 'I = regular; deficiente para menos', abbr: 'I' },
        { dinamismoA: 'II = bom; eficiente', abbr: 'II' },
      ],
      MatrizOptions: [
        { matriz: 'M >', abbr: 'MG' },
        { matriz: 'M <', abbr: 'ML' },
        { matriz: 'P >', abbr: 'PG' },
        { matriz: 'P <', abbr: 'PL' },
      ],
      UfOptions: [
        'AC', 'AL', 'AP', 'AM',	
        'BA', 'CE', 'DF', 'ES',	
        'GO', 'MA', 'MT', 'MS',	
        'MG', 'PA', 'PB', 'PR',
        'PE',	'PI', 'RJ', 'RN',
        'RS', 'RO', 'RR', 'SC',	
        'SP', 'SE'	
      ],
      nameRules: [
        v => !!v || 'O nome é obrigatório',
      ],
      emailRules: [ // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v)
        v => !!v || 'O E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'Digite um E-mail válido',
      ]
    }
  },

  mounted () {
    this.getPatients()
  },

  computed: { 
    check(){
      return this.sourceEditedIndex != -1  ? true : false
    },

    // disabledTransvision: function() {
    //   if (this.sourceEditedIndex != -1)
    //     await this.seedUser(this.editedItem.patient)
        
    // },

    finalEditedItem: {
      get: function (){
        this.editedItem = this.sourceEditedItem
        return this.editedItem
      },
    },

    title: function (){
      return this.sourceEditedIndex > -1 ? 'Editando Ficha de Transvisão' : 'Nova Ficha de Transvisão'
    },
  },

  methods: {
    setDate(date){
      this.finalEditedItem.data_tv = date
    },
    
    autoCompletePatientData (data){
      if (data.length > 0){
        if (data[0].birth_date)
          this.finalEditedItem.idade = new Date().getFullYear() - parseInt(data[0].birth_date.split('-')[0])
        else 
          this.finalEditedItem.idade = '--'
        
        if (data[0].gender)
          this.finalEditedItem.genero = {genero: data[0].gender_display, abbr: data[0].gender}
        else 
          this.finalEditedItem.genero = {genero: "Masculino", abbr: "M"}

        this.finalEditedItem.patient = data[0].id
        //  this.finalEditedItem.numero_transvisoes = 11
        // this.finalEditedItem.intervalo_ultima_transvisao = 11
        this.getTransvisionHistory(data[0].id)
        this.setSetenio(this.finalEditedItem.idade)
     }

    },

    setSetenio(age){
      if (!isNaN(age)){
        const setenio = Math.ceil(age / 7) != 0 ? Math.ceil(age / 7) : 1
        // if (age > 63) // nao há mais que 9 setenios
        //   this.finalEditedItem.setenio = 9 + '°'
        // else
        this.finalEditedItem.setenio = setenio.toString() + '°'
      }
      else 
        this.finalEditedItem.setenio = '--'
    },

    async getTransvisionHistory(patient_pk){
      this.loading = true
      await transvisionHistory(patient_pk)
       .then(res => {
        const total = res.data.transvisions_info.total_tranvisions
        const interval = res.data.transvisions_info.transvision_interval
        this.finalEditedItem.numero_transvisao = total
        this.finalEditedItem.intervalo_ultima_transvisao = interval
        return {total, interval}
      })
      .catch(err => {
        if(err.response){ // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        } 
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
      this.loading = false
    },

    validate () {
      this.$refs.form.validate()
    },

    close () {
      this.$emit('close')
      this.patient = ''      
      this.resetMsg()
    },

    resetMsg (){
      this.msg = ""
    },

    formatGender(){
      if (typeof(this.finalEditedItem.gender) === 'object'){ // curiosidade: typeof(null) é object também
        const {gender, abbr} = this.finalEditedItem.gender
        this.finalEditedItem.gender_display = gender
        this.finalEditedItem.gender = abbr
      }
    },

    async getPatients () {
      this.loading = true
      await axios.get(
        `/sessions/patients/`, {
        })
      .then(res => {
        this.patients = res.data && res.data.results ? res.data.results : [{}]
        this.patient = res.data && res.data.results ? this.patients[0] : ''
      })
      .catch(err => {
        if(err.response){ // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        } 
        else if (err.request)// response nunca recebida
          console.log("error: never received a response: ")
        else 
          console.log("error: ")
      })
      // this.$emit('patients', this.patients)
      this.loading = false
    },

    choicesHandler(){
      if (typeof(this.finalEditedItem.genero) === 'object'){ // curiosidade: typeof(null) é object também
        const {gender, abbr} = this.finalEditedItem.genero
        this.finalEditedItem.genero_display = gender
        this.finalEditedItem.genero = abbr
      }

      if (typeof(this.finalEditedItem.estado_civil) === 'object'){ // curiosidade: typeof(null) é object também
        const { civilState, abbr } = this.finalEditedItem.estado_civil
        this.finalEditedItem.estado_civil_display = civilState
        this.finalEditedItem.estado_civil = abbr
      }

      if (typeof(this.finalEditedItem.domicilio) === 'object'){ // curiosidade: typeof(null) é object também
        const { domicile, abbr} = this.finalEditedItem.domicilio
        this.finalEditedItem.domicilio_display = domicile
        this.finalEditedItem.domicilio = abbr
      }
      
      if (typeof(this.finalEditedItem.profissao_trabalho) === 'object'){ // curiosidade: typeof(null) é object também
        const { profission, abbr }= this.finalEditedItem.profissao_trabalho
        this.finalEditedItem.profissao_trabalho_display = profission
        this.finalEditedItem.profissao_trabalho = abbr
      }

      if (typeof(this.finalEditedItem.profissao_financeiro) === 'object'){ // curiosidade: typeof(null) é object também
        const { remuneration, abbr } = this.finalEditedItem.profissao_financeiro
        this.finalEditedItem.profissao_financeiro_display = remuneration
        this.finalEditedItem.profissao_financeiro = abbr
      }

      if (typeof(this.finalEditedItem.dm_passado_m) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dm_passado_m
        this.finalEditedItem.dm_passado_m_display = dinamismo
        this.finalEditedItem.dm_passado_m = abbr
      }

      if (typeof(this.finalEditedItem.dm_passado_p) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dm_passado_p
        this.finalEditedItem.dm_passado_p_display = dinamismo
        this.finalEditedItem.dm_passado_p = abbr
      }

      if (typeof(this.finalEditedItem.dp1_passado_m) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dp1_passado_m
        this.finalEditedItem.dp1_passado_m_display = dinamismo
        this.finalEditedItem.dp1_passado_m = abbr
      }

      if (typeof(this.finalEditedItem.dp1_passado_p) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dp1_passado_p
        this.finalEditedItem.dp1_passado_p_display = dinamismo
        this.finalEditedItem.dp1_passado_p = abbr
      }

      if (typeof(this.finalEditedItem.dp2_passado_m) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dp2_passado_m
        this.finalEditedItem.dp2_passado_m_display = dinamismo
        this.finalEditedItem.dp2_passado_m = abbr
      }

      
      if (typeof(this.finalEditedItem.dp2_passado_p) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dp2_passado_p
        this.finalEditedItem.dp2_passado_p_display = dinamismo
        this.finalEditedItem.dp2_passado_p = abbr
      }

      if (typeof(this.finalEditedItem.df_passado) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.df_passado
        this.finalEditedItem.df_passado_display = dinamismo
        this.finalEditedItem.df_passado = abbr
      }
      
      if (typeof(this.finalEditedItem.dm_presente_m) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dm_presente_m
        this.finalEditedItem.dm_presente_m_display = dinamismo
        this.finalEditedItem.dm_presente_m = abbr
      }

      if (typeof(this.finalEditedItem.dm_presente_p) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dm_presente_p
        this.finalEditedItem.dm_presente_p_display = dinamismo
        this.finalEditedItem.dm_presente_p = abbr
      }

      if (typeof(this.finalEditedItem.dp1_presente_m) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dp1_presente_m
        this.finalEditedItem.dp1_presente_m_display = dinamismo
        this.finalEditedItem.dp1_presente_m = abbr
      }

      if (typeof(this.finalEditedItem.dp1_presente_p) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dp1_presente_p
        this.finalEditedItem.dp1_presente_p_display = dinamismo
        this.finalEditedItem.dp1_presente_p = abbr
      }

      if (typeof(this.finalEditedItem.dp2_presente_m) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr }= this.finalEditedItem.dp2_presente_m
        this.finalEditedItem.dp2_presente_m_display = dinamismo
        this.finalEditedItem.dp2_presente_m = abbr
      }

      if (typeof(this.finalEditedItem.dp2_presente_p) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr } = this.finalEditedItem.dp2_presente_p
        this.finalEditedItem.dp2_presente_p_display = dinamismo
        this.finalEditedItem.dp2_presente_p = abbr
      }

      if (typeof(this.finalEditedItem.df_presente) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismo, abbr } = this.finalEditedItem.df_presente
        this.finalEditedItem.df_presente_display = dinamismo
        this.finalEditedItem.df_presente = abbr
      }

      if (typeof(this.finalEditedItem.da1_presente) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismoA, abbr }= this.finalEditedItem.da1_presente
        this.finalEditedItem.da1_presente_display = dinamismoA
        this.finalEditedItem.da1_presente = abbr
      }

      if (typeof(this.finalEditedItem.da2_presente) === 'object'){ // curiosidade: typeof(null) é object também
        const { dinamismoA, abbr }= this.finalEditedItem.da2_presente
        this.finalEditedItem.da2_presente_display = dinamismoA
        this.finalEditedItem.da2_presente = abbr
      }

      if (typeof(this.finalEditedItem.vla) === 'object'){ // curiosidade: typeof(null) é object também
        const { matriz, abbr }= this.finalEditedItem.vla
        this.finalEditedItem.vla_display = matriz
        this.finalEditedItem.vla = abbr
      }

      if (typeof(this.finalEditedItem.v4) === 'object'){ // curiosidade: typeof(null) é object também
        const { matriz, abbr }= this.finalEditedItem.v4
        this.finalEditedItem.v4_display = matriz
        this.finalEditedItem.v4 = abbr
      }

      if (typeof(this.finalEditedItem.v5) === 'object'){ // curiosidade: typeof(null) é object também
        const { matriz, abbr }= this.finalEditedItem.v5
        this.finalEditedItem.v5_display = matriz
        this.finalEditedItem.v5 = abbr
      }
    },

    async saveValidatedData () {
      this.loading = true
     
      this.choicesHandler()
      const editing = this.sourceEditedIndex > -1 ? true : false
      if (editing) {
        await updateTransvision(this.finalEditedItem) // contem *_display
        .then(res => {
          if (res && res.data) // uso original em vez res.data pq quro usar _display
            this.$emit('save', res.data)    
        })
        .catch(err => {
          if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: x ")
          }
          else if (err.request) { // response nunca recebida
            console.log("error: never received a response: ")
          }
          else {
            console.log("error: ")
          }
        })
      }
      else { // criando
        await createTransvision(this.finalEditedItem)
        .then(res => {
          this.$emit('save', res.data)   
        })
        .catch(err => {
          if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
          }
          else if (err.request) { // response nunca recebida
            console.log("error: never received a response: ")
          }
          else {
            console.log("error: ")
          }
        })
      }
      this.loading = false
      this.close()
    },  
  }
}
</script>
<style scoped>

  .lower {
    text-transform: lowercase;
  }
  
  .uper {
    text-transform: uppercase;
  }

  .testee {
    flex-grow: 1;
    overflow: auto;
  }
</style>
<template>
  <v-card>
    <v-card-title class="text-h6 white--text indigo">
       Excluindo categoria
    </v-card-title>
    <v-card-text>
       <v-container>
         <v-row no-gutters>
          <v-col cols=12>
            <p color="red" class="subtitle-1 font-weight-medium">{{msg}} </p>
          </v-col>
          <v-col>
            <p class="font-weight-bold">{{targetCategory}}</p>
          </v-col>
         </v-row>
       </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-divider dark inset></v-divider>
        <v-col cols=12 align-self="center">  <!-- class="text-right"-->
          <v-btn class="ma-1" width='25vh' color="primary" white--text @click="close">Cancelar</v-btn>
          <v-btn class="ma-1" width='25vh' color="error" white--text @click="deleteCategory">Excluir</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
//axios.defaults.baseURL = 'http://localhost:8000/'

export default {
  props: {
    targetId: {
      type: String,
      default: ''
    },
    targetCategory: {
      type: String,
      default: ''
    }
  },

  data: function (){
    return {
      loading: false,
      msg: 'Tem certeza de que deseja excluir a categoria:'
    }
  },

  methods: {
    close () {
      this.$emit('close'),
      this.sefDefaultMsg()
    },
    sefDefaultMsg(){
      this.msg = 'Tem certeza de que deseja excluir a categoria:'
    },
    async deleteCategory(){
      this.loading = true
      const pk = this.targetId
      // console.log(" vo remover ", pk)
      if (pk == '67f7fd43-357c-42f9-9ccc-be2a44cc8f69'){ // valor do fixture do backend
        this.msg = 'Não é permitido remover esta categoria.'
        return
      }
      await axios.delete(
        `/sessions/topics/categories/delete/${pk}`)
      .then(res => {
        // console.log("category ", this.targetCategory, " successfully deleted.")
        this.$emit('deleted')
      })
      .catch(err => {
        if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ", err.response.data)
        else if (err.request) // response nunca recebida
          console.log("error: never received a response: ", err.request)
        else
          console.log("error: ", err)
      })
      this.loading = false
    }
  }
}
</script>
<template>
   <v-container  class="pa-0" fluid fill-height>
    

    <v-layout column justify-center align-center>
      <v-col class="d-flex align-center justify-center">
       <v-card width="600px " class="card-search elevation-12">

          <v-toolbar
            color="primary"
            dark
            flat
          >
          <v-toolbar-title>Trocar senha</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-snackbar width="600px"
              v-model="snackbar"
              :timeout="2500"
              :color="notificationData.color"
              botton
              >
              <span> {{notificationData.msg}} </span>
            </v-snackbar>
          </v-toolbar>
          <!-- <v-slide-x-transition hide-on-leave> -->
            <v-card-text>
              <v-form>
                <div v-if="logging">
                  atualizando dados...
                  <v-progress-linear 
                    indeterminate
                    color="blue darken-2"
                  ></v-progress-linear>
                </div>


                <v-row justify="center">
                  <v-col cols=10>
                    <v-text-field
                      label="Usuário"
                      autocomplete="new-password"
                      v-model.trim="email"
                      append-icon="mdi-account"
                      type="text"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols=10>
                    <v-text-field
                      label="Nova senha"
                      autocomplete="new-password"
                      v-model.trim="password"
                      :append-icon="isShowing ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="isShowing = !isShowing"
                      :type="(isShowing) ? 'text': 'password'">
                    </v-text-field>
                  </v-col>
                </v-row>

              </v-form>
            </v-card-text>
          <!-- </v-slide-x-transition>
           -->
          <!-- <v-slide-x-transition hide-on-leave> -->
            <v-card-text>
              <v-row justify-center>
                  <v-col cols=12>
                    <v-btn :disabled="password ? false : true" @click="changePassword" minWidth="500px" maxHeight="50px" x-large color="primary">Alterar senha</v-btn>
                  </v-col> 
              </v-row>
            </v-card-text>
          <!-- </v-slide-x-transition>           -->
        </v-card>
      </v-col>
      <v-card
        class="my-5 mx-auto"
        max-width="400"
        tile
      >
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'
import router from '../router'
import { changePassword } from '../api'

  export default {
    

    data: function (){
      return {
        dialog: false,
        password: '',
        email: '',
        logging: false,
        isShowing: false,
        snackbar: false,
        token: '',
        notificationData: {
          color: "error",
          msg: "Usuário ou Token inválidos"
        }
      }
    },

    mounted (){
      this.token = this.$route.query.token
      if (!this.token)
        router.push('/login')
      
    },

    methods: {
      noticationHandler(color, msg, status){
        this.notificationData.color = color//"success"
        this.notificationData.msg = msg
        this.snackbar = status
      },

      // close () {
      //   this.$emit('close')
      //   this.email = ''
      // },

      async changePassword(){
        this.loading = true
        const data = {
          "email": this.email,
          "password": this.password  
        }
        
        await changePassword({"data": data, "token": this.token})
        .then(res => {
          // console.log("sucess ", res )
          router.push({name: 'Login' , params: { changed: true }})
        })
        .catch(err => {
          if(err.response){
            this.snackbar = true
            // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
            // this.$emit('notify', {msg: "usuário ou token inválidos", color: "error"})
           // this.close()
          }
          else if (err.request) // response nunca recebida
            console.log("error: never received a response: ")
          else
            console.log("error: ")
        })
        this.loading = false
      }
    },
    
  
  }
</script>
<style scoped>
  .forgot-pass {
    cursor: pointer;
  }
</style>
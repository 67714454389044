<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="finalDate"
          label="Data de nascimento"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="finalDate"
        :active-picker.sync="activePicker"
        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
        min="1950-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
  
  export default {
    props: {
      date: {
        type: String,
        default: (new Date('1956', '01', '01')).toISOString().substr(0, 10)
      },
    },

    data: function () {// nao use arrow functions, se nao nao poderia usar o this!
      return {
        innerDate: this.date,
        activePicker: null,
        menu: false,
      }
    },

    watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    
    computed: {
      finalDate: {
        get: function (){
          return this.date
        },

        set: function (date){
          this.innerDate = date
        }
      }
    },

    methods: {
      save (date) {
        this.$refs.menu.save(date)
        this.$emit('date-changed', date)
      },
    },
  }
</script>
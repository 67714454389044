import Vue from 'vue'
import VueRouter from 'vue-router'
import Patients from '../views/Patients.vue'
import Sessions from '../views/Sessions.vue'
import Dashboard from '../views/Dashboard.vue'
import Categories from '../views/Categories.vue'
import Dicts from '../views/Dicts.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Recover from '../views/Recover.vue'
import Transvisions from '../views/Transvisions.vue'
import MdHandler from '../components/transvisions/MdHandler.vue'
import { getUser, refresh } from '../api/index'
import axios from 'axios'

Vue.use(VueRouter)

const otherRoutes = "Others"

const routes = [
  {
    path: '/recover',
    name: 'Recover',
    component: Recover,
   // meta: { requiresAuth: true }
  },

  {
    path: '/',
    name: 'Home',
    component: Home,
   // meta: { requiresAuth: true }
  },

  {
    path: '/login',
    name: 'Login',
    component: Login,

  },
  
  {
    path: '/sessions',
    name: 'Sessions',
    component: Sessions,
   // meta: { requiresAuth: true }
  
  },

  {
    path: '/Patients',
    name: 'Patients',
    component: Patients,
  //  meta: { requiresAuth: true }
  },
  
  {
    path: '/categories',
    name: 'Categories',
    component: Categories,
   // meta: { requiresAuth: true }
  },

  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
   // meta: { requiresAuth: true }
  },

  {
    path: '/md',
    name: 'MdHandler',
    component: MdHandler,
   // meta: { requiresAuth: true }
  },

  {
    path: '/dicts',
    name: 'Dicts',
    component: Dicts,
  //  meta: { requiresAuth: true }
  },

  {
    path: '/transvisions',
    name: 'Transvisions',
    component: Transvisions,
  //  meta: { requiresAuth: true }
  },

  {
    path: '*',
    name: otherRoutes,
   // meta: { requiresAuth: true }
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/recover']; //, '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  // trying to access a restricted page + not logged in
  // redirect to login page
  
  const logged = await ping()
  if (authRequired && !logged) {
    next('/login');
  } else {
      next();
  }
});

async function ping (){
  return await axios.get('/users/ping/', {timeout: 10000} )
  .then(res => {
    return true
  })
  .catch(err => {
    if(err.response) {// client recebeu resposta de erro: (4xx,5xx etc)
      console.log("error: bad ping response: ** ")
      localStorage.removeItem("userLogged")
    }
    else if (err.request)// response nunca recebida
      console.log("error: never received a ping response: ** ")
    else 
      console.log("error: **")
    return false
  })
}

export default router

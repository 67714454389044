<template>
  <v-form 
    ref="form"
    v-model="valid"
    lazy-validation>
    <v-card>
      <v-card-title class="text-h6 white--text indigo">
        {{title}}
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row 
              align="center"
              justify="center">
            <v-col   
              cols="5"
              sm="5"
              md="5">
              <v-text-field
                autofocus
                v-model.trim="finalEditedItem.word_key"
                label="Atalho"
                maxlength="50"
                :rules="wordKeyRules"
                required
              ></v-text-field>
            </v-col>
            <v-col
              cols="2"
              sm="2"
              md="2">
              <v-icon large>mdi-arrow-right-bold</v-icon>
            </v-col>
            <v-col   
              cols="5"
              sm="5"
              md="5">
              <v-text-field
                autofocus
                v-model.trim="finalEditedItem.word_map"
                label="Palavra"
                maxlength="50"
                :rules="wordMapRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions> <!--class="text-h6 white--text indigo"> -->
        <v-row no-gutters>
          <v-col cols=12>
            <p class="subtitle-2 font-weight-medium red--text">{{msg}}</p>
          </v-col>
          <v-col cols=12>
            <v-progress-linear v-if="loading"
              indeterminate
              class="mb-4"
            ></v-progress-linear>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right" cols=12>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ma-1"
              white--text
              @click="close"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="ma-1"
              :disabled="!valid"
              white--text
              @click="saveValidatedData"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import axios from 'axios'
//axios.defaults.baseURL = 'http://localhost:8000/'

export default {
  props: {
    sourceEditedIndex: {
      type: Number,
      default: -1,
    },
    sourceEditedItem: {
      type: Object,
      default: { 
        id: "", 
        word_key: "", 
        word_map: "", //indigo
      }
    },
  },

  data: function() {
    return {
      valid: true,
      loading: false,
      addrLoading: false,
      msg: "",
      colorPickerWidth: "250",
      // editedIndex: this.sourceEditedIndex,
      editedItem: this.sourceEditedItem, // this.formatAddressForShow(),//this.sourceEditedItem,
      wordKeyRules: [
        v => !!v || 'A palavra é obrigatória',
      ],
      wordMapRules: [
        v => !!v || 'O atalho obrigatório',
      ],
    }
  },

  computed: { 
    finalEditedItem: {
      get: function (){
        this.editedItem = this.sourceEditedItem
        return this.editedItem
      },
    },

    title: function (){
      return this.sourceEditedIndex > -1 ? 'Editando Mapeamento' : 'Novo Mapeamento'
    }
  },

  methods: {
    validate () {
      this.$refs.form.validate()
    },

    close () {
      this.$emit('close')
      this.resetMsg()
    },

    resetMsg (){
      this.msg = ""
    },

    async saveValidatedData () {
      // console.log("save() called, received from child: ", data)
      this.loading = true
    
      if (this.sourceEditedIndex > -1) { // EDITING  
        const pk = this.finalEditedItem.id

        await axios.patch(`/sessions/words-map/update/${pk}/`, this.finalEditedItem)
          .then(res => {
            const data = this.finalEditedItem
            console.log("category updated successfully: ")
            this.resetMsg()
            this.$emit('save', data)
          })
          .catch(err => {
            if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
              console.log("error: bad response: ")
              if(err.response.data && err.response.data.word_key) {
                const word_key = this.finalEditedItem.word_key.toLowerCase()
                const msg = `Já existe uma entrada no dicionário para a palavra ${word_key}.`
                this.msg = msg
                this.$emit('resend-data', this.finalEditedItem)
              }
            }
            else if (err.request) { // response nunca recebida
              console.log("error: never received a response: ")
            }
            else {
              console.log("error: ")
            }
          })
        
      } else { // CREATING
        await axios.post(`/sessions/words-map/create/`, this.finalEditedItem)
        .then(res => {
          console.log("category created successfully: ")
          this.resetMsg()
          this.$emit('save', res.data)
        })
        .catch(err => {
          if(err.response) { // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
            if(err.response.data && err.response.data.word_key) {
              const word_key = this.finalEditedItem.word_key.toLowerCase()
              const msg = `Já existe uma entrada no dicionário para a palavra ${word_key}.`
              this.msg = msg
              this.$emit('resend-data', this.finalEditedItem)
            }
          }
          else if (err.request) { // response nunca recebida
            console.log("error: never received a response: ")
          }
          else {
            console.log("error: ")
          }
        })
      }
      this.loading = false
    },  
  }
}
</script>
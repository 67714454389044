var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"custom-shadow",style:({backgroundColor: _vm.localTopic.color})},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"categories-options",attrs:{"disabled":_vm.disabled,"hide-details":"","background-color":"white","rounded":true,"dense":"","height":"30","items":_vm.categories.map(function (ref) {
	var name = ref.name;

	return name;
}),"return-object":""},on:{"change":_vm.setColor},model:{value:(_vm.localTopic.topicName),callback:function ($$v) {_vm.$set(_vm.localTopic, "topicName", $$v)},expression:"localTopic.topicName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"5"}},[_c('v-text-field',{staticClass:"my-title",attrs:{"disabled":_vm.disabled,"hide-details":"","filled":"","dense":"","background-color":"white"},on:{"input":_vm.resetSavedStatus},model:{value:(_vm.localTopic.title),callback:function ($$v) {_vm.$set(_vm.localTopic, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localTopic.title"}})],1),(_vm.$route.name == 'Dashboard')?_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"links-bernal",attrs:{"disabled":_vm.disabled,"color":_vm.saveBtnColor,"depressed":"","small":""},on:{"click":_vm.saveHandler}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"white"}},[_vm._v(" "+_vm._s(_vm.saveBtnIcon)+" ")])],1)]}}],null,false,691159718)},[_c('span',[_vm._v(_vm._s(_vm.saveBtnMsg))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"links-bernal ml-4",attrs:{"disabled":_vm.disabled,"color":"yellow darken-2","depressed":"","x-small":"","fab":""}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"white"}},[_vm._v(" mdi-help ")])],1)]}}],null,false,3764052495)},[_c('span',[_vm._v("Sessão: "+_vm._s(_vm.topic.session_date))])])],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-textarea',{staticClass:"my-text-area mt-2 px-2",attrs:{"disabled":_vm.disabled,"hide-details":"","text-narrow":"","name":"input-7-1","rows":"3"},on:{"keyup":_vm.getLastWordTyped,"click":_vm.clearLastWord},model:{value:(_vm.localTopic.description),callback:function ($$v) {_vm.$set(_vm.localTopic, "description", $$v)},expression:"localTopic.description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"my-1",attrs:{"indeterminate":"","color":"yellow darken-2"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        hide-details
        v-model="innerDate"
        :label="label"
        hint="MM/DD/YYYY format"
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>

    </template>
    
    <v-date-picker
      v-model="innerDate"
      no-title
      @input="menu1 = false"
      @change="setDate"
    ></v-date-picker>

  </v-menu>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    props: {
      label: {
        type: String,
        default: 'Data'
      },
      date: {
        type: String,
        default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      }
    },

    data: function(){
      return {
        innerDate: this.date,
        menu1: false,
        dashBoardRoute: 'Dashboard', // nome da rota
      }
    },

    computed: {
      ...mapGetters('sessions', {
        editedItem: 'editedItem',
        editedIndex: 'editedIndex',
        editedItemEditableClone: 'editedItemEditableClone',
        
      }),
    },

    watch: {
      date (val) {
        // console.log("Mudou date ", val)
        this.innerDate = val
      },
     
    },

    methods: {
      

      setDate(date){
        this.innerDate = date
        this.$emit('set-date', date)
      },


      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },

      parseDate (date) {
        if (!date) return null
        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
  }
</script>
<template>



   <v-container>
     <v-col cols=12>
        <p class="text-uppercase font-weight-black text-center">
          {{title}}
        </p>
        <v-divider></v-divider>
      </v-col>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" depressed small
            :disabled="finalCompreentions.length <= PROTECTED_BORDER + 1  ? true : false"
            color="primary"
            class="ma-1"
            @click="remove">
            <v-icon class="mx-2"
                medium
              >
              mdi-minus
            </v-icon>
          </v-btn>
        </template>
        <span>Remover compreensão</span>
      </v-tooltip>
      
      <!-- <v-btn
        color="primary"
        class="ma-1"
        white--text
        @click="remove"
      >
         <v-icon>mdi-minus</v-icon>
      </v-btn> -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" depressed small
            :disabled="finalCompreentions.length > PROTECTED_MAX - 1 ? true : false"
            color="primary"
            class="ma-1"
            @click="add">
            <v-icon class="mx-2"
                medium
              >
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Adicionar compreensão</span>
      </v-tooltip>

      <!-- <v-btn
        color="primary"
        class="ma-1"
        white--text
        @click="add"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn> -->

    <div v-for="(item, i) in finalCompreentions" :key="i">
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-textarea
          v-model="finalCompreentions[finalCompreentions.indexOf(item)].description"
          :label=" 'Compreensão após transvisão ' + (i+1)"
          hide-details
          text-narrow
          name="input-7-1"
          rows="4"
          outlined
        ></v-textarea>
      </v-col>
      
    </div>
  </v-container>
</template>

<script>

// import MyAppBar from './components/globals/MyAppBar'
// import MyDrawer from './components/globals/MyDrawer'

export default {
  components: {
    //MyAppBar,
  },

  props: {
    title: {
      type: String,
      default: 'title'
    },

    sourceCompreentions: {
      type: Array,
      default () {
        return [
          {
            'description': '--'
          },{'description': '--'},{
            'description': '--'
          }
        ]
      }
    },
    
  },

  data: function() {
    return {
      // compreensions: [
      //   'a', 'b', 'c'
      // ],
      PROTECTED_BORDER: 1,
      PROTECTED_MAX: 100, // max 100 compreensoes numa transvisao
      compreentions: this.sourceCompreentions
    }
  },

  computed: { 
    finalCompreentions: {
      get: function (){
        this.compreentions = this.sourceCompreentions
        return this.compreentions
      },
    },
  },

  methods: { //n use computed, cache enganoso
    add (){
      const index = this.finalCompreentions.length -1;

      if(index < this.PROTECTED_MAX - 1)
        this.finalCompreentions.push({"description": "--"})
    },

    remove (){
      const index = this.finalCompreentions.length -1;
      if (index > -1 && index > this.PROTECTED_BORDER) { // n pode remover indice 0 ou 1
        this.finalCompreentions.splice(index, 1);
      }

    }
  }
}
</script>

<style>



</style>

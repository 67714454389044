<template>
  <v-sheet class="home-panel-outer" height="100%">
    <v-container class="home-panel-inner" fill-height>
      <!-- <v-row no-gutters class="d-flex justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" color="primary" to="/patients" large fab depressed>
                <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                  mdi-account-group
                </v-icon>
              </v-btn>
            </template>
            <span>Pacientes</span>
          </v-tooltip>
          <v-col cols=2>
            <v-text-field max-width="10px"
              value="Pacientes"
              filled
              rounded
              disabled
            ></v-text-field>
          </v-col>
      </v-row>

      <v-row no-gutters class="d-flex justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" color="primary" to="/categories" large fab depressed>
                <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                  mdi-tag-text-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Categorias</span>
          </v-tooltip>
          <v-col cols=2>
            <v-text-field max-width="10px"
              value="Categorias"
              filled
              rounded
              disabled
            ></v-text-field>
          </v-col>
      </v-row>

      <v-row no-gutters class="d-flex justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" color="primary" to="/dicts" large fab depressed>
                <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                  mdi-book-open-variant
                </v-icon>
              </v-btn>
            </template>
            <span>Dicionário</span>
          </v-tooltip>
          <v-col cols=2>
            <v-text-field max-width="10px"
              value="Dicionário"
              filled
              rounded
              disabled
            ></v-text-field>
          </v-col>
      </v-row>

      <v-row no-gutters class="d-flex justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" color="primary" to="/sessions" large fab depressed>
                <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                  mdi-clipboard-multiple-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Sessões</span>
          </v-tooltip>
          <v-col cols=2>
            <v-text-field max-width="10px"
              value="Sessões"
              filled
              rounded
              disabled
            ></v-text-field>
          </v-col>
      </v-row>

      <v-row no-gutters class="d-flex justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" color="primary" to="/dashboard" large fab depressed>
                <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                  mdi-desktop-mac-dashboard 
                </v-icon>
              </v-btn>
            </template>
            <span>Dashboard</span>
          </v-tooltip>
          <v-col cols=2>
            <v-text-field max-width="10px"
              value="Dashboard"
              filled
              rounded
              disabled
            ></v-text-field>
          </v-col>
      </v-row>

      <v-row no-gutters class="d-flex justify-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" color="primary" to="/" large fab depressed>
                <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                  mdi-logout
                </v-icon>
              </v-btn>
            </template>
            <span>Logout</span>
          </v-tooltip>
          <v-col cols=2>
            <v-text-field max-width="10px"
              value="Logout"
              filled
              rounded
              disabled
            ></v-text-field>
          </v-col>
      </v-row> -->

      <v-row>
        <my-card 
          icon="mdi-account-group"
          title="Pacientes"
          to="/patients"/>
      </v-row>
      <v-row>
        <my-card 
          icon="mdi-tag-text-outline"
          title="Categorias"
          to="/categories"/>
      </v-row>
      <v-row>
        <my-card 
          icon="mdi-book-open-variant"
          title="Dicionário"
          to="/dicts"/>
      </v-row>
      <v-row>
         <my-card 
          icon="mdi-clipboard-multiple-outline"
          title="Sessões"
          to="/sessions"/>
      </v-row>
      <v-row>
        <my-card 
          icon="mdi-desktop-mac-dashboard "
          title="Dashboard"
          to="/dashboard"/>
      </v-row>
       <v-row>
        <my-card 
          icon="mdi-eye-settings-outline"
          title="Transvisões"
          to="/transvisions"/>
      </v-row>
     <!-- <v-row>
        <my-card 
          icon="mdi-desktop-mac-dashboard "
          title="Dashboard"/>
      </v-row> -->

    </v-container>
  </v-sheet>
</template>
<script>
import MyCard from '../components/home/MyCard.vue'

export default {
  components: {
    MyCard
  }

}
</script>
<style scoped>

/* .home-panel-outer{
  border: 3px solid #3F51B5; /** #B359CA*/
 /* border-radius: 5px;
} */

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"dark":"","color":"primary","max-height":"55px"}},[_c('v-toolbar-title',{staticClass:"mx-2"},[_vm._v(_vm._s(_vm.title))]),_c('v-toolbar-items',[_vm._t("items")],2),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-2",attrs:{"color":"white","to":"/patients","depressed":"","small":""}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-account-group ")])],1)]}}])},[_c('span',[_vm._v("Pacientes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-1",attrs:{"color":"white","depressed":"","to":"/categories","small":""}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-tag-text-outline ")])],1)]}}])},[_c('span',[_vm._v("Categorias")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-1",attrs:{"color":"white","depressed":"","small":"","to":"/dicts"}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-book-open-variant ")])],1)]}}])},[_c('span',[_vm._v("Dicionário")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-1",attrs:{"color":"white","depressed":"","small":"","to":"sessions"}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-clipboard-multiple-outline ")])],1)]}}])},[_c('span',[_vm._v("Sessões")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-1",attrs:{"color":"white","depressed":"","to":"/dashboard","small":""}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-desktop-mac-dashboard ")])],1)]}}])},[_c('span',[_vm._v("Dashboard")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-1",attrs:{"color":"white","depressed":"","to":"/transvisions","small":""}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-eye-settings-outline ")])],1)]}}])},[_c('span',[_vm._v("Transvisões")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-1",attrs:{"color":"white","to":"/","depressed":"","small":""}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-home ")])],1)]}}])},[_c('span',[_vm._v("Home")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"d-flex links-bernal mx-1",attrs:{"color":"white","depressed":"","small":""},on:{"click":_vm.logout}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"medium":"","color":"primary"}},[_vm._v(" mdi-logout ")])],1)]}}])},[_c('span',[_vm._v("Logout")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from 'axios'
import {alternatedSort} from '../../utils/sort'

const state = () => ({
  dateA: undefined,
  dateB: undefined,
  categoryA: undefined,
  categoryB: undefined,
  patient: undefined,
  patientName: undefined,
  dashboardSessions: [], //passarei ela para a store sessions...bagunca mas vida que segue
  displayZebraCat: {
    canFindZebraPathern: false, // util pra trocar as colunas dps de alternar duas categorias dif. udado pro getter zebramodediff
    goToColum: 0, // sempre 0 ou 1, pois sao 2 colunas sempre no caso de mais de uma cat distinta no filtro
    moveIndexAfter: 0, // a partir de ql indice (inclusive), deve-se deslocar um topic pra coluna 'correta'
    moveSituation: false, // de fato é pra mudar a posicao de algum(s) topico(s)
    colZeroCat: undefined,
    //useAsRef: 0  // padrap é usar o 0 pra a e 1 pra b. caso tenha que trocar a ormde de exibicao,  usarei 1 pra a e 0 pra b
  },
})

const mutations = {
  setPatient(state, data){
    state.patient = data.patient.id
    state.patientName = data.patient.name
  },
  setDateA(state, dateA){
    state.dateA = dateA
  },
  setDateB(state, dateB){
    state.dateB = dateB
  },
  setCategoryA(state, data){
    if (data)
      state.categoryA = data.id
    else 
      state.categoryA = data
  },
  setCategoryB(state, data){
    if (data)
      state.categoryB = data.id
    else 
      state.categoryB = data
  },
  setDashboardSessions(state, payload){
    if (payload.zebraFlag){
      var merged_array = payload.data.map(({topics}) => topics).flat()
      merged_array = [{
        id: payload.data.length > 0 ? payload.data[0].id : undefined,
        patient: payload.data.length > 0 ? payload.data[0].patient : state.patient,
        patient_name: payload.data.length > 0 ? payload.data[0].patient_name : state.patientName,
        topics: merged_array,
        created_at: payload.data.length > 0 ? payload.data[0].created_at : undefined,
        session_date: payload.data.length > 0 ? payload.data[0].session_date : undefined
      }]
      // VER DPS. A logica é correta, mas o display no sessionsheet teria que mudar e isso da mt trabalho
      if (state.categoryA && state.categoryB && (state.categoryA !== state.categoryB)){ // nesse caso, bote o catA como o primeiro elemento, pro display respeitar a ordem que o user botou
        state.displayZebraCat.colZeroCat = state.categoryA
        let catA = merged_array[0].topics.filter( topic => topic.topic_category === state.categoryA);
        let catB = merged_array[0].topics.filter( topic => topic.topic_category === state.categoryB);
        if (catA.length && catB.length){
          if(merged_array[0].topics[0].topic_category !== state.categoryA){
            // ajustra pra respeitar a ordem passada pelo user no filtro de cat
            const swapIdx = merged_array[0].topics.findIndex((it) => it.topic_category == state.categoryA)
            const aux = merged_array[0].topics[0]
            merged_array[0].topics[0] = merged_array[0].topics[swapIdx]
            merged_array[0].topics[swapIdx] =  aux
          }
        }
      }
      merged_array[0].topics.forEach(function(it, i, arr) {
        alternatedSort(it,i, arr)
      })
      state.dashboardSessions = merged_array
      state.displayZebraCat.canFindZebraPathern = merged_array[0].topics.length > 0 ? true : false
      // console.log("tamanho ", state.dashboardSessions[0].topics.length, " lu ", state.displayZebraCat.canFindZebraPathern)
    } else {
      state.dashboardSessions = payload.data
      state.displayZebraCat.canFindZebraPathern = false
    }
  },
  updateDashboardSession(state, payload){
    // state.sessions[payload.editedIndex] = payload.editedItem // N FUNCIONA
    const updateSession = payload.editedItem

    state.dashboardSessions = [ // JEITO CORRETO DE FAZER O UPDATE!
      ...state.dashboardSessions.filter(session => session.id !== payload.editedItem.id),
      updateSession
    ]
  },
  clearDataExcepPatient(state){
    state.dateA = ''
    state.dateB = ''
    state.categoryA = ''
    state.categoryB = ''
    // state.patient = ''
  },
  setMoveSituation (state, status) { // em organizeCollumDisplay chamo state.displayzebract blabla direto pq n pode chamar commits em cascata e na action , faz sentido.
    state.displayZebraCat.moveSituation = status
  },
  organizeColumnDisplay (state) {
    // somente qd tiver 2 categorias para mostrar,
    // ve a cat com mais itens e quantos itens sao etc
    if (
      state.categoryA && state.categoryB &&
      (state.categoryA !== state.categoryB) &&
      state.dashboardSessions && state.displayZebraCat.canFindZebraPathern) {

      const topicsLen = state.dashboardSessions[0].topics.length
      const catALen = state.dashboardSessions[0].topics.filter(topic => topic.topic_category == state.categoryA).length
      const catBLen = state.dashboardSessions[0].topics.filter(topic => topic.topic_category == state.categoryB).length

      if (catALen > 0 && catBLen > 0){
        const delta = Math.abs(catALen - catBLen)
        const firstDifIdx = topicsLen - delta
        
        if (delta == 0) // msm numero de topicos nas 2 categorias, faz nada mete o pe
          return
       
        const refCat = state.dashboardSessions[0].topics[firstDifIdx - 2].topic_category 
        if (refCat == state.dashboardSessions[0].topics[firstDifIdx].topic_category){
          state.displayZebraCat.goToColum = 0
          state.displayZebraCat.moveIndexAfter = firstDifIdx
        }
        else{
          state.displayZebraCat.goToColum = 1
          state.displayZebraCat.moveIndexAfter = firstDifIdx
        }
        state.displayZebraCat.moveSituation = true
        // console.log("delta ", delta, " topic len ", topicsLen, " first", firstDifIdx, " vv ", refCat, " col ", state.displayZebraCat.goToColum)      
      } else 
        state.displayZebraCat.moveSituation = false
    } else {
      state.displayZebraCat.moveSituation = false
    }
  }

}

const actions = {
  setPatient({commit}, data){
    commit('setPatient', data)
  },
  setDateA({commit}, data){
    commit('setDateA', data)
  },
  setDateB({commit}, data){
    commit('setDateB', data)
  },
  setCategoryA({commit}, data){
    commit('setCategoryA', data)
  },
  setCategoryB({commit, state}, data){
    commit('setCategoryB', data)
  },
  async querySessions({state, commit}){
    var queryData = {
      patient: state.patient
    }
    if (state.dateA)
      queryData.dateA = state.dateA
    if (state.dateB)
      queryData.dateB = state.dateB
    if (state.categoryA)
      queryData.categoryA = state.categoryA
    if (state.categoryB)
      queryData.categoryB = state.categoryB
    await axios.get(
      `/sessions/sessions/dashboard-sessions/`,{
      params: {
        patient: state.patient,
        idate: state.dateA,
        fdate: state.dateB,
        'cat-a': state.categoryA,
        'cat-b': state.categoryB
      }
    }) // ?page=${targetPage}${qs}`)
    .then(res => {
      const payload = {
        data: res.data.results,
        zebraFlag: state.categoryA || state.categoryB ? true : false
      }
      // console.log("veja zebra flag ", state.categoryA ," e ", state.categoryB, " logo ", payload.zebraFlag)
      commit('setDashboardSessions', payload)
      if (payload.zebraFlag)
        commit('organizeColumnDisplay')
      else 
        commit('setMoveSituation', false)
      
      //commit('setPatients', res.data.results)
    })
    .catch(err => {
      if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
        console.log("error: bad response: ")
      else if (err.request)// response nunca recebida
        console.log("error: never received a response: ")
      else 
        console.log("error: ")
    })
  },
  clearDataExcepPatient({commit}){
    commit('clearDataExcepPatient')
  }
}

const getters = {
  categoryA (state){
    return state.categoryA
  },
  categoryB (state){
    return state.categoryB
  },
  dashboardSessions (state){
    return state.dashboardSessions
  },
  zebraMode (state) {
    return state.categoryA || state.categoryB ? true : false
  },
  displayZebraCat (state) {
    return state.displayZebraCat
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
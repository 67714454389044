<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        
        hide-details
        v-model="innerDate"
        :label="label"
        hint="MM/DD/YYYY format"
        persistent-hint
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>

    </template>
    
    <v-date-picker
      v-model="innerDate"
      no-title
      @input="menu1 = false"
      @change="setDate"
    ></v-date-picker>

  </v-menu>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'

  export default {
    props: {
      label: {
        type: String,
        default: 'Data'
      },
      date: {
        type: String,
        default: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      clearDateAFlag: { // so dashboard que usa
        type: Boolean,
        default: false
      },
      clearDateBFlag: { // so dashboard que usa, preciso de A, B em vez de só 1 pq tenho 2 instancias
        type: Boolean, // do msm componente, porem eles n executarao no msm tempo, d e modo q só um
        default: false // funcionaria...usando 2 flags. ambos funcionarão!
      },
      dateFlag: { // distinguir no dashboard qual data foi escolhida: A ou B
        type: String
      }
    },

    data: function(){
      return {
        innerDate: this.date,
        menu1: false,
        dashBoardRoute: 'Dashboard', // nome da rota
      }
    },

    computed: {
      ...mapGetters('sessions', {
        editedItem: 'editedItem',
        editedIndex: 'editedIndex',
        editedItemEditableClone: 'editedItemEditableClone',
        
      }),
    },

    watch: {
      date (val) {
        console.log("Mudou date ", val)
        this.innerDate = val
      },
      clearDateAFlag(val){
        this.setAFlag(val)
      },
      clearDateBFlag(val){
        this.setBFlag(val)
      }
    },

    methods: {
      setAFlag(){ //util no dashboard pra limpar uma data (pq é opcional e tal)
        this.innerDate = ''
        this.$emit('setAFlag', false)
        this.$store.dispatch('dashboard/setDateA', undefined)
      },
      setBFlag(){ //util no dashboard pra limpar uma data (pq é opcional e tal)
        this.innerDate = ''
        this.$emit('setBFlag', false)
        this.$store.dispatch('dashboard/setDateB', undefined)
      },

      setDate(date){
        this.innerDate = date
        console.log("chamei setDate", date)
        if (this.$route.name != this.dashBoardRoute) // logica pra sessao
          this.$store.dispatch('sessions/setDate', date)
        else{
          const flag = this.dateFlag
          const storePath = flag == 'A' ? 'setDateA' : 'setDateB'
          console.log("papa pop", this.dateFlag)
          this.$store.dispatch(`dashboard/${storePath}`, date)


        }
        
      },


      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },

      parseDate (date) {
        if (!date) return null
        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
  }
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('session-toolbar',{attrs:{"title":_vm.title},scopedSlots:_vm._u([{key:"items",fn:function(){return [_c('v-container',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('auto-complete',{attrs:{"item":_vm.editedItemEditableClone.patient_name},on:{"disableDashboardSearchBtn":_vm.disableDashboardSearchBtn}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('category-auto-complete',{attrs:{"catFlag":"A"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('category-auto-complete',{attrs:{"catFlag":"B"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('date-picker',{attrs:{"dateFlag":"A","date":_vm.date,"clearDateAFlag":_vm.clearDateAFlag,"label":"data inicial"},on:{"setAFlag":_vm.setAFlag}})],1),_c('v-col',{staticClass:"mt-2 d-flex",attrs:{"cols":"12","sm":"2","md":"2"}},[_c('date-picker',{attrs:{"dateFlag":"B","date":_vm.date,"clearDateBFlag":_vm.clearDateBFlag,"label":"data final"},on:{"setBFlag":_vm.setBFlag}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"primary","fab":"","x-small":"","depressed":""},on:{"click":_vm.clearDate}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close-thick ")])],1)]}}])},[_c('span',[_vm._v("Limpar filtros de data")])])],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.queryBtnDisabled,"color":"primary","fab":"","small":"","depressed":""},on:{"click":_vm.querySessions}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-magnify ")])],1)]}}])},[_c('span',[_vm._v("Buscar sessões")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"warning","fab":"","small":"","depressed":"","disabled":_vm.currentIndex -1 < 0 ? true : false},on:{"click":function($event){return _vm.previousSession()}}},on),[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]}}])},[_c('span',[_vm._v("Sessão anterior")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"color":"warning","fab":"","small":"","depressed":"","disabled":_vm.currentIndex +1 >= _vm.sessionIdsList.length ? true : false},on:{"click":function($event){return _vm.nextSession()}}},on),[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}])},[_c('span',[_vm._v("Próxima sessão")])])],1)]},proxy:true}])}),_c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"timeout":_vm.snackTimeout,"center":"","color":"success"},model:{value:(_vm.snackStatus),callback:function ($$v) {_vm.snackStatus=$$v},expression:"snackStatus"}},[_c('h3',{staticClass:"d-flex justify-center"},[_vm._v(_vm._s(_vm.snackMsg))])])],1),(_vm.loading)?_c('v-progress-linear',{staticClass:"my-1",attrs:{"indeterminate":"","color":"yellow darken-2"}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[(_vm.noDataFlag == false)?_c('session-sheet'):_c('v-container',[_c('h3',[_vm._v(_vm._s(_vm.noDataMsg))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-app>
      <!-- <my-drawer/> -->
      <my-app-bar v-if="$route.name != 'Dashboard' && $route.name != 'Home' && $route.name != 'Login' && $route.name != 'Others' && $route.name != 'Recover' && showBar()"/>
      <v-main>
        <router-view/>
      </v-main>
    </v-app>
</template>

<script>

import MyAppBar from './components/globals/MyAppBar'
// import MyDrawer from './components/globals/MyDrawer'

export default {
  components: {
    MyAppBar,
  },

  methods: { //n use computed, cache enganoso
    showBar () {
      return  localStorage.getItem("userLogged") === "true"
    }
  }
}

</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>

<template>
    <div>
      <session-toolbar :title="title">
        <template v-slot:items>
          <v-container class="d-flex">
            <v-row>
              <v-col 
              cols=12
              sm="3"
              md="3"
              class="mt-1"
              >
              
                <auto-complete :item="editedItemEditableClone.patient_name"
                @disableDashboardSearchBtn="disableDashboardSearchBtn"/>
              </v-col>
              <v-col
                cols=12
                sm="2"
                md="2">
                <category-auto-complete catFlag="A"></category-auto-complete>
              </v-col>
              <v-col
               cols=12
                sm="2"
                md="2">
                <category-auto-complete catFlag="B"></category-auto-complete>
              </v-col>
              <v-col  
                cols=12
                sm="2"
                md="2"
                class="mt-2">
                <date-picker
                  @setAFlag="setAFlag"
                  dateFlag="A"
                  :date="date"
                  :clearDateAFlag="clearDateAFlag"
                  label="data inicial"/>
              </v-col>
              <v-col
                cols=12
                sm="2"
                md="2"
                class="mt-2 d-flex">
                <date-picker
                  @setBFlag="setBFlag"
                  dateFlag="B"
                  :date="date"
                  :clearDateBFlag="clearDateBFlag"
                  label="data final"/>
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"                      class="ml-2"
                      color="primary" @click="clearDate" fab x-small depressed>
                      <v-icon dark>
                        mdi-close-thick
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Limpar filtros de data</span>
                  </v-tooltip>
              </v-col>
            </v-row>
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" :disabled="queryBtnDisabled" color="primary" fab small depressed @click="querySessions">
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>
              <span>Buscar sessões</span>
              </v-tooltip>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                v-on="on"
                class="mx-1" 
                color="warning" fab small depressed
                :disabled="currentIndex -1 < 0 ? true : false" @click="previousSession()">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>Sessão anterior</span>
            </v-tooltip>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                v-on="on"
                class="mx-1" 
                color="warning" fab small depressed
                :disabled="currentIndex +1 >= sessionIdsList.length ? true : false" @click="nextSession()">
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>Próxima sessão</span>
            </v-tooltip>
          </v-container>
        </template>
      </session-toolbar>
      <div class="text-center">
        <v-snackbar
          v-model="snackStatus"
          :timeout="snackTimeout"
          center
          color="success"
        >
          <h3 class="d-flex justify-center">{{snackMsg}}</h3>
        </v-snackbar>
      </div>
      <v-progress-linear
        v-if="loading"
        indeterminate
        class="my-1"
        color="yellow darken-2"
      ></v-progress-linear>
      <div v-show="!loading">
      <session-sheet v-if="noDataFlag == false"/>
      <v-container v-else>
        <h3 >{{noDataMsg}}</h3>
      </v-container>
      </div>
     
    </div>
</template>

<script>
  import axios from 'axios'
  import SessionSheet from '../components/sessions/SessionSheet'
  import AutoComplete from '../components/sessions/AutoComplete'
  import DatePicker from '../components/sessions/DatePicker'
  import SessionToolbar from '../components/sessions/SessionToolBar'
  import CategoryAutoComplete from '../components/sessions/CategoryAutoComplete'

  import { mapState, mapGetters } from 'vuex'

  //axios.defaults.baseURL = 'http://localhost:8000/'
  export default {
    name: 'SessionDialog',
    
    computed: {
      ...mapGetters('sessions', {
        editedItem: 'editedItem',
        dialogStatus: 'dialogStatus',
        editedIndex: 'editedIndex',
        editedItemEditableClone: 'editedItemEditableClone',
        sessions: 'sessions',
        topics: 'topics',
        patients: 'patients',
      }),

      ...mapGetters('dashboard', {
        dashboardSessions: 'dashboardSessions',
        canFindZebraPathern: 'canFindZebraPathern'
      }),

      disableNextPage(){
        return false
      },
      
      title(){
        return ''
      },
    },
    
    components: {
      AutoComplete,
      CategoryAutoComplete,
      DatePicker,
      SessionSheet,
      SessionToolbar
    },
  
    data: function(){
      return {
        sessionIdsList: [],
        dialog: this.dialogStatus,
        loading: false,
        currentIndex: 0,
        date: "",
        clearDateAFlag: false,
        clearDateBFlag: false,
        queryBtnDisabled: true,
        firtsLoadFlow: true, //gambiarra pra rodar o flow correto ao carregar pela primerai vez
        noDataFlag: true, //mostrar mensagem caso nao tenha sessoes a se exibir
        noDataMsg: "Nenhuma Sessão encontrada",
        snackStatus: false,
        snackMsg: "Busca realizada com sucesso",
        snackTimeout: 1000

      }
    },
  
    mounted() {
      // this.getSessions()
      this.getPatients()
    },

    methods: {
      async querySessions(){
        this.loading = true
        await this.$store.dispatch('dashboard/querySessions')
        this.sessionIdsList = this.dashboardSessions.map(session => session.id)
        this.resetIndex()
        this.setSession()
        this.loading = false
        this.snackStatus = true
      },
   
      resetIndex(){ // previne o bug das setas ficarem habilitadas qd nao deveriam
        this.currentIndex = 0
      },
      
      clearDate(){
        this.date = ""
        this.clearDateAFlag = true
        this.clearDateBFlag = true
      },

      setAFlag(flag){
        this.clearDateAFlag = flag
      },

      setBFlag(flag){
        this.clearDateBFlag = flag
      },
      disableDashboardSearchBtn (status) {
        this.queryBtnDisabled = status
      },
      async getPatients (targetPage, qs = '') {
        // ate seria legal, mas o codigo nao tá otimizando as apias nem usando
        // a store de forma tao inteligente...entao fara sempre a query e vida que segue irmao
        // if (this.patients.length == 0){
          this.loading = true
          await this.$store.dispatch('sessions/getPatientsDashboard')
          this.loading = false
        // }
        // else {
        //   console.log("pacientes já setados!", this.patients)
        // }
        this.queryBtnDisabled = false
        this.getSessions()
      },

      async getSessions (targetPage, qs = '') {
        this.loading = true
        await this.$store.dispatch('sessions/getSessions')
        const payload = {
          data: this.sessions,
          zebraFlag: false
        }
        this.$store.commit('dashboard/setDashboardSessions', payload)
        this.sessionIdsList = this.dashboardSessions.map(session => session.id)//this.sessions.map(session => session.id)
        this.loading = false
        if (this.sessionIdsList.length > 0)
          this.setSession()
          
      },

      setSession(){
        const tgtSessionId = this.sessionIdsList[0]
        const tgtSession = this.dashboardSessions.find(session => session.id == tgtSessionId)//this.sessions.find(session => session.id == tgtSessionId)
        if (tgtSessionId){
          this.noDataFlag = false
          this.showSession(tgtSession)
        }
        else{
          this.noDataFlag = true
        }
      },

      nextSession(){
        const i = this.getIndex(true)
        const tgtSessionId = this.sessionIdsList[i]
        const tgtSession = this.dashboardSessions.find(session => session.id == tgtSessionId)//this.sessions.find(session => session.id == tgtSessionId)
        this.showSession(tgtSession)
      },

      previousSession(clearDatesFlag = false){
        const i = this.getIndex(false)
        const tgtSessionId = this.sessionIdsList[i]
        const tgtSession = this.dashboardSessions.find(session => session.id == tgtSessionId)//this.sessions.find(session => session.id == tgtSessionId)
        this.showSession(tgtSession)
      },

      getIndex(next){
        if (this.currentIndex < this.dashboardSessions.length -1 && next)//this.sessions.length -1 && next)
          this.currentIndex ++
        else if(this.currentIndex > 0  && !next)
          this.currentIndex --
        return this.currentIndex
      },

      // zuado em victor... mas dps refatore vai... ou aprenda a licao
      async showSession(item){
        const editedIndex = this.dashboardSessions.indexOf(item)//this.sessions.indexOf(item)
        const data = {editedItem: item, editedIndex: editedIndex}
        // essa ordem é essencial pra tudo correr bem!!
        await this.$store.dispatch('sessions/setDefaultItem') 
        await this.$store.dispatch('sessions/setEditableItem', data)
        await this.$store.dispatch('sessions/setItem', data)
        if (this.firtsLoadFlow){//pra td rodar correto na primeira vez que carregar o dashboard lol
          // corre risco de internamente der algo setado msm nao parecendo
          // esse clearData garantirah que limpara td antes de exibir o primeiro flow
          await this.$store.dispatch('dashboard/clearDataExcepPatient')
          this.querySessions()
          this.firtsLoadFlow = false
        }
      }
    }
  }
</script>

<template>
  <categories-table
    :title="title"
    />
</template>

<script>
  import axios from 'axios'

  import CategoriesTable from '../components/categories/CategoriesTable'
  
  //axios.defaults.baseURL = 'http://localhost:8000/'

  export default {
    name: 'Categories',

    components: {
      CategoriesTable,
    },

    data: () => ({
      title: 'Categorias',
    }),
  }

</script>


<template> 
  <patients-table
    :title="title"
    />
</template>

<script>
  import axios from 'axios'

  import PatientsTable from '../components/patients/PatientsTable'
  
  // axios.defaults.baseURL = 'http://localhost:8000/'

  export default {
    name: 'Patients',

    components: {
      PatientsTable
    },

    data: () => ({
      title: 'Pacientes',
    }),

    // mounted () {
    //   this.$store.dispatch('auth/ping')
    // }
  }

</script>


<template>
    <v-sheet 
      class="topic-wrapper"
    > 
    <v-progress-linear v-if="loading"
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>
      <v-row 
        no-gutters
        v-for="(row,i) in topicLen">
        <v-col 
          class="pa-1"
          cols=12
          sm="6"
          md="6"
        >
          <div v-if="displayZebraCat.moveSituation && $route.name == 'Dashboard'">
            <div v-if="displayZebraCat.goToColum == 0">
              <topic
                :topic="topics[2*i]" :topicIndex="(2*i)"
              ></topic>
              <topic class="mt-2" v-if="(2*i) >= displayZebraCat.moveIndexAfter && (2*i + 1) < topics.length"
                :topic="topics[2*i + 1]" :topicIndex="(2*i + 1)"
              ></topic>
            </div>
            <div v-else>
              <topic v-if="(2*i) < displayZebraCat.moveIndexAfter"
                :topic="topics[2*i]" :topicIndex="(2*i)"
              ></topic>
            </div>
          </div>
          <div v-else>
            <topic 
              :topic="topics[2*i]" :topicIndex="(2*i)"
            ></topic>
          </div>
        </v-col>
     
        <v-col 
          class="pa-1"
          cols=12
          sm="6"
          md="6"
          v-if="colsManager(i, topics[2*i + 1])"
        >
          <div v-if="displayZebraCat.moveSituation && $route.name == 'Dashboard'">   
            <div v-if="displayZebraCat.goToColum == 0">
              <topic v-if="(2*i +1) < displayZebraCat.moveIndexAfter && (2*i + 1) < topics.length" 
                :topic="topics[2*i + 1]" :topicIndex="(2*i +1)"
              ></topic>
            </div>
            <div v-else>
              <topic
                :topic="topics[2*i + 1]" :topicIndex="(2*i +1)"
              ></topic>
              <topic class="mt-2" v-if="(2*i +1) >= displayZebraCat.moveIndexAfter && (2*i) < topics.length"
                :topic="topics[2*i]" :topicIndex="(2*i)"
              ></topic>
              <!-- isso que faltava pra tudo funcionar perfeito! ver desenha do caso 1 e vera q faz sentido -->
              <topic class="mt-2" v-if="2*i + 1 == topics.length - 2"
                 :topic="topics[2*i + 2]" :topicIndex="(2*i)"
              ></topic>
            </div>
           
          </div>
          <div v-else>
            <topic
              :topic="topics[2*i + 1]" :topicIndex="(2*i +1)"
            ></topic>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
</template>

<script>
  import axios from 'axios'
  import Topic from './Topic'
  import { mapGetters, mapState } from 'vuex'

  // axios.defaults.baseURL = 'http://localhost:8000/'
  export default {
    name: 'Session',
    components: {
      Topic,
    },

    mounted(){
      //se ba dps por em sessionBeta.vue
      this.getCategories(),
      this.getDictionary()
    },

    computed: {
      ...mapGetters('sessions', {
        categories: 'categories',
        dictionary: 'dictionary',
        editedItem: 'editedItem',
        editedIndex: 'editedIndex',
        topics: 'topics', // store pegou os topics do editedItem ;),
        loading: 'loading',
        topicLen: 'topicLen',
        editedItemEditableClone: 'editedItemEditableClone'
      }),

      ...mapGetters('dashboard', {
        zebraMode: 'zebraMode',
        dashboardSessions: 'dashboardSessions',
        displayZebraCat: 'displayZebraCat',
        categoryA: 'categoryA',
        categoryB: 'categoryB',
      }),

      showDefaultDash: function() {
        return this.displayZebraCat.moveSituation && this.$route.name == 'Dashboard'
      }
    },
    data: () => ({
      rows: 5,
      cols: 2,
      title: 'Sessões',
      topic: {},
    }),
   
    methods: {
      colsManager(i, value){
        if (this.editedItemEditableClone.topics.length % 2 != 0){
           return i == this.topicLen -1 ? false : true
        }
        else
          return true
      },
      
      async getCategories(){
        await this.$store.dispatch('sessions/getCategories')
      },

      async getDictionary(){
        await this.$store.dispatch('sessions/getDictionary')
      },
    }
  }
</script>

<style>

.painel {
  background-color: rgb(10, 23, 95);
}

.topic-wrapper {
  overflow-y: auto;
  height: 90vh;
}

</style>
<template>
  <v-card>
    <v-card-title class="text-h6 white--text indigo">
       Excluindo paciente
    </v-card-title>
    <v-card-text>
       <v-container>
         <v-row no-gutters>
          <v-col cols=12>
            <p class="subtitle-1 font-weight-medium">Tem certeza de que deseja excluir o paciente: </p>
          </v-col>
          <v-col>
            <p class="font-weight-bold">{{targetEmail}}</p>
          </v-col>
         </v-row>
       </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-divider dark inset></v-divider>
        <v-col cols=12 align-self="center">  <!-- class="text-right"-->
          <v-btn class="ma-1" width='25vh' color="primary" white--text @click="close">Cancelar</v-btn>
          <v-btn class="ma-1" width='25vh' color="error" white--text @click="deletePatient">Excluir</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
//axios.defaults.baseURL = 'http://localhost:8000/'

export default {
  props: {
    targetId: {
      type: String,
      default: ''
    },
    targetEmail: {
      type: String,
      default: ''
    }
  },

  data: function (){
    return {
      loading: false
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    async deletePatient(){
      this.loading = true
      const pk = this.targetId
      await axios.delete(
        `/sessions/patients/delete/${pk}`)
      .then(res => {
        console.log("user ", this.targetEmail, " successfully deleted.")
        this.$emit('deleted')
      })
      .catch(err => {
        if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
          console.log("error: bad response: ")
        else if (err.request) // response nunca recebida
          console.log("error: never received a response: ")
        else
          console.log("error: ", err)
      })
      this.loading = false
    }
  }
}
</script>
<template>
  <v-container class="container py-2">
    <v-data-table
      height="68vh"
      :headers="headers"
      :items="tableItems"
      :loading="loading"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 dictionary-maps-table"
    >

     <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
        <v-tooltip bottom :key="header.value" >
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ header.text }}</span>
          </template>
          <span v-if="header.value != 'actions'">ordenar por {{ header.text.toLowerCase() }} 
            em ordem de criação, ordem ascendente ou ordem descendente, respectivamente</span>
          <span v-else>Ações</span>
        </v-tooltip>
      </template>

      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-icon class="mx-4"
            large
            color="primary"
          >
            mdi-book-open-variant
          </v-icon>
          <v-toolbar-title > {{title}} </v-toolbar-title>
          <v-divider 
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <!-- <v-spacer></v-spacer> -->
          <v-row no-gutters>
            <v-col cols=11>
              <v-text-field
                v-model.trim="search"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols=1>
               <v-tooltip bottom class="veryLongTooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  @click="handleSearch(-1)"
                  class="mx-2"
                  fab
                  small
                  depressed
                  color="primary"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>
                <span>Buscar mapeamento por um atalho ou palavra do dicionário.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            :max-width="maxDictionaryMapFormWidth"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Novo Mapeamento
                <v-icon
                  right
                  dark
                  large
                >
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            
            <dictionary-map-form
              :sourceEditedItem="editedItem"
              :sourceEditedIndex="editedIndex"
              @resend-data="formatAddressForChild"
              @close="close"
              @save="save"
            />

          </v-dialog>
          <v-dialog 
            v-model="dialogDelete" 
            :max-width="maxDictionaryMapDeleteFormWidth"
            persistent>
              <dictionary-map-delete-form
                :targetId="editedItem.id"
                :targetDictionaryWordKey="editedItem.word_key"
                :targetDictionaryWordMap="editedItem.word_map"
                @close="closeDelete"
                @deleted="deleteItemConfirm"/>
          </v-dialog>  
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-on="on"
              class="mr-2"
              @click="editItem(item)"
              medium
              color="#FCB700"
            >
              mdi-pencil
            </v-icon>
          </template>
        <span>Editar mapeamento</span>
        </v-tooltip>
         <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            @click="deleteItem(item)"
            medium
            color="red"
          >
            mdi-delete
          </v-icon>
        </template>
      <span>Excluir mapeamento</span>
      </v-tooltip>
      </template>

      <!-- qd nao tiver item cadastrado -->
      <template v-slot:no-data>
        <p class="font-weight-black indigo--text">
          Nenhum mapeamento encontrado
        </p>
      </template>
      <template v-slot:footer>
        <div class="text-center">
          <div> total de mapeamentos encontrados: {{showtotalDictionaryMap}} </div>
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="getDesiredPage"
          ></v-pagination>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>
  
<script>
  import axios from 'axios'
  import DictionaryMapForm from './DictionaryMapForm'
  import DictionaryMapDeleteForm from './DictionaryMapDeleteForm'

  //axios.defaults.baseURL = 'http://localhost:8000/'

  export default {
    props: {
      title: String
    },

    components: {
      DictionaryMapForm,
      DictionaryMapDeleteForm
    },

    data: () => ({
      search: '',
      dialog: false,
      dialogDelete: false,
      headers: [
        // { text: 'Id', value: 'id', class: 'white--text' },
        { text: 'Atalho', value: 'word_key', class: 'white--text' },
        { text: 'Palavra', value: 'word_map', class: 'white--text' },
        { text: 'Ações', value: 'actions', sortable: false, class: 'white--text' },

      ],
      tableItems: [],
      defaultItem: {
        id: "", 
        word_key: "", 
        word_name: "" // indigo
      },
      maxDictionaryMapFormWidth: '500px',
      maxDictionaryMapDeleteFormWidth: '500px',
      loading: true,
      items: [],
      totalDictionaryMap: 0, // vem da api
      currentPage: 1,
      pageControler: 1, // evitar que chame a api qd chamar uma mesma pagina
      itemsPerPage: 8, // por de acordo com oq eu definir no backend
      editedIndex: -1,
      editedItem: {},
    }),
    
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nova Categoria' : 'Editando Categoria'
      },

      showtotalDictionaryMap (){
        return this.totalDictionaryMap
      },

      totalPages (){
        var ammount = Math.ceil(this.totalDictionaryMap / this.itemsPerPage)
        return (isNaN(ammount) || ammount == 0 ? 1 : ammount)
      },
    },

    mounted () {
      this.getDictionaryMap(this.currentPage)
      this.setDefaultItem()
    },
    
    methods: {
   
      handleSearch(page = -1){
        if (page == -1){
          this.currentPage = 1
          this.pageControler = 1
          page = 1 // pra buscar primeira do filter query
        }
        if (this.search){
          this.getDictionaryMap(page, `&search=${this.search}`)
        }
        else{
          this.getDictionaryMap(page)
        }
      },

      getDesiredPage(v){ //v == this.currentPage por conta do v-model
        if (this.pageControler != v){
          //this.getDictionaryMap(v)
          this.handleSearch(v)
          this.pageControler = this.currentPage
        }
      },

      async getDictionaryMap (targetPage, qs = '') {
        this.loading = true
        await axios.get(
          `/sessions/words-map/?page=${targetPage}${qs}`)
        .then(res => {
          this.tableItems = res.data && res.data.results ? res.data.results : [{}]
          this.totalDictionaryMap = res.data && res.data.count ? res.data.count : 0
        })
        .catch(err => {
          if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
          else if (err.request)// response nunca recebida
            console.log("error: never received a response: ")
          else 
            console.log("error: ")
        })
        this.loading = false
      },

      async editItem (item) {
        // console.log("Editando item...")
        this.editedIndex = this.tableItems.indexOf(item)
        this.formatAddressForChild(item)
        this.dialog = true
      },

      formatAddressForChild(item){
        item = { ...item, ...item.address }
        delete item.address
        this.editedItem = Object.assign({}, item)
      },

      deleteItem (item) {
        this.editedIndex = this.tableItems.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.tableItems.splice(this.editedIndex, 1)
        this.totalDictionaryMap -=1
        this.closeDelete()
      },

      setDefaultItem() {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      },

      setDate(date){
        this.editedItem.birth_date = date
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      
      save (data){
        if (this.editedIndex > -1){ // editando paciente
          Object.assign(this.tableItems[this.editedIndex], data)
          this.close()
        }
        else { // novo paciente
          this.tableItems.unshift(data)
          this.close()
          this.totalDictionaryMap += 1
        }
      }
    },
  }
</script>

<style>
  

  .dictionary-maps-table table {
    border: 5px solid #3F51B5; /** #B359CA*/
    border-radius: 10px;
  }

  .dictionary-maps-table td  {
    border: 1px solid #3F51B5;  /** #B359CA*/
    background-color: white;  /**#883A9B*/
    /* text-align: center; */
  }
  
  .dictionary-maps-table td.text-start {
    color:  black; /**cor dos itens antiquewhite;*/
    font: 14px;
    font-weight: normal; /**bold */
  }
  
  .dictionary-maps-table  th {
    background-color: #3F51B5;  /** #B359CA*/
    border: 2px solid #3F51B5;  /** #B359CA*/
  }
  
</style>
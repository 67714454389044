import Vue from 'vue'
import Vuex from 'vuex'
import { login, logout, refresh } from '../../api/index'
import router from '../../router'
import jwt_decode from "jwt-decode";
import axios from 'axios';


// axios.defaults.baseURL = 'http://localhost:8000/'

Vue.use(Vuex)

  
const state = () => ({
  authUser: null,
})

const mutations = {
  setTokenExp: (state, accessToken) => {
    const decodedData = jwt_decode(accessToken)
    localStorage.setItem("exp", decodedData.exp.toString())
    // lgd = logged ahaha
  },

  setAuthUser: (state, authUser) => {
    state.authUser = authUser
    if(state.authUser !== null){
      localStorage.setItem("userLogged", "true")
      localStorage.setItem("user", JSON.stringify(state.authUser))
      router.push('/')
    }
    else{
      localStorage.removeItem("userLogged")
      localStorage.removeItem("user")
      localStorage.removeItem("exp")
      if (router.currentRoute.name !== 'Login')
        router.push('/login')
    }
  }
}

const actions = {
  async ping (){
    return await axios.get('/users/ping/', {timeout: 10000} )
    .then(res => {
      return true
    })
    .catch(err => {
      if(err.response) {// client recebeu resposta de erro: (4xx,5xx etc)
        console.log("error: bad ping response: ")
      }
      else if (err.request)// response nunca recebida
        console.log("error: never received a ping response: ")
      else 
        console.log("error: ")
      return false
        
    })
  },

  async login ({state, commit}, user){
    return await login(user)
    .then(res => {
      console.log("sucess ", res.data)
      commit("setAuthUser", res.data.user)
      commit("setTokenExp", res.data.access_token)
      return true
    })
    .catch(err => {
      if(err.response) {// client recebeu resposta de erro: (4xx,5xx etc)
        console.log("error: bad response: ")
      }
      else if (err.request)// response nunca recebida
        console.log("error: never received a response: ")
      else 
        console.log("error: ")
      return false
    })
  },

  async logout ({state, commit}){
    await logout()
    .then(res => {
      commit("setAuthUser", null)
    })
    .catch(err => {
      if(err.response) {// client recebeu resposta de erro: (4xx,5xx etc)
        console.log("error: bad response: ")
      }
      else if (err.request)// response nunca recebida
        console.log("error: never received a response: ")
      else 
        console.log("error: ")
    })
  },

  async refresh({state, commit}){
    await refresh()
    .then(res => {
      console.log("sucess ")
    })
    .catch(err => {
      if(err.response) {// client recebeu resposta de erro: (4xx,5xx etc)
        console.log("error: bad response: ")
      }
      else if (err.request)// response nunca recebida
        console.log("error: never received a response: ")
      else 
        console.log("error: ")
    })
  },
}

const getters = {
  authUser (state){
    return state.authUser
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
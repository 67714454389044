<template>
  <v-container :style="{backgroundColor: localTopic.color}" class="custom-shadow">
    <v-row> 
      <v-col 
        cols=12
        sm="12"
        md="4">
        <v-autocomplete
          :disabled="disabled"
          hide-details 
          v-model="localTopic.topicName"
          background-color="white"
          :rounded="true"
          dense
          height="30"
          class="categories-options"
          :items="categories.map(({ name }) => name)"
          return-object 
          @change="setColor"
        >
        </v-autocomplete>
      </v-col>
      <v-col
        cols=12
        sm="12"
        md="5">
        <v-text-field
          :disabled="disabled"
          hide-details
          filled
          dense
          class="my-title"
          background-color="white"
          v-model.trim="localTopic.title"
          @input="resetSavedStatus"
        ></v-text-field>
      </v-col>
      <v-col v-if="$route.name == 'Dashboard'" class="d-flex align-center" cols="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
                v-on="on"
                :disabled="disabled"
                @click="saveHandler"
                :color="saveBtnColor" 
                depressed 
                small 
                class="links-bernal">
                  <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                    {{saveBtnIcon}} 
                  </v-icon>
            </v-btn>
          </template>
          <span>{{saveBtnMsg}}</span>
        </v-tooltip>
        
         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
                v-on="on"
                :disabled="disabled"
                color="yellow darken-2" 
                depressed 
                x-small
                fab
                class="links-bernal ml-4">
                  <v-icon class="mx-2"
                    medium
                    color="white"
                  >
                    mdi-help
                  </v-icon>
            </v-btn>
          </template>
          <span>Sessão: {{topic.session_date}}</span>
        </v-tooltip>

      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
        >
        <v-textarea
          :disabled="disabled"
          hide-details
          text-narrow
          class="my-text-area mt-2 px-2"
          v-model="localTopic.description"
          @keyup="getLastWordTyped"
          @click="clearLastWord"
          name="input-7-1"
          rows="3"
        ></v-textarea>
      </v-col>
      <v-col cols=12>
        <v-progress-linear
          v-if="loading"
          indeterminate
          class="my-1"
          color="yellow darken-2"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import axios from 'axios'

  // axios.defaults.baseURL = 'http://localhost:8000/'

  export default {
    props: {
      topic: {
        type: Object,
        default: function () {
          return { // tá de acordo com o fixture do backend
            description: "--",
            id: undefined, //"c37d13cc-71c0-4f2a-a84c-8c99068bb981",
            session: undefined, //"39f61413-112c-45d3-8c17-b473431cebe9",
            title: "---",
            topic_category: "67f7fd43-357c-42f9-9ccc-be2a44cc8f69",
            topic_category_details: {
              color: "#EBFE22FF",
              id: "67f7fd43-357c-42f9-9ccc-be2a44cc8f69",
              name: "Categoria"
            }
          }
        }
      },
      topicIndex: {
        type: Number,
        default: 1
      }
    },

    data: function () {// nao use arrow functions, se nao nao poderia usar o this!
      return {
        lastWordTyped: '',
        currentText: "",
        currentCategory: "",
        currentColor: "#3F51B5",
        saved: true,//false,
        disabled: false,
        loading: false,
        sessionId: null,
     
        localTopic: {
          topicName: this.topic.topic_category_details.name,
          title: this.topic.title,
          description: this.topic.description,
          color: this.topic.topic_category_details.color
        }
      }
    },

    computed: {
      ...mapGetters('sessions', {
        categories: 'categories',
        editedItem: 'editedItem',
        dictionary: 'dictionary',
        topics: 'topics',
        sessions: 'sessions',
        editedItemEditableClone: 'editedItemEditableClone',
        desiredRows2: 'desiredRows2'
      }),

      ...mapGetters('dashboard', {
        dashboardSessions: 'dashboardSessions'
      }),

      saveBtnColor: function (){
        if (this.saved == true)
          return 'success'
        else 
          return 'yellow darken-2'
      },

      saveBtnIcon: function (){
        if(this.saved == true)
          return 'mdi-check-bold'
        else 
          return 'mdi-alert-circle-outline'
      },

      saveBtnMsg: function (){
        if(this.saved == true)
          return 'Tópico salvo'
        else 
          return 'Atenção, este tópico foi alterado. Clique aqui para salva-lo.'
      }
    },

    watch: {
      localTopic: {
        handler(){
          this.setLocalTopic()
        },
        deep: true,
      }
    },

    methods: {
      setLocalTopic(dashboardFlag = false){
        const topic_category = this.categories.find(cat => cat.name === this.localTopic.topicName)
        var data = {
          description: this.localTopic.description,
          id: this.topic.id,
          session: this.topic.session,
          session_date: this.topic.session_date,
          title: this.localTopic.title,
          topic_category: topic_category.id,
          topic_category_details: {
            color: this.localTopic.color,
            topicName: this.localTopic.topicName,
            id: topic_category.id
          },
          topicIndex: this.topicIndex,
        }
        // ESSA LINHA Q FAZ FAZER EDITED E DATA (EDITED COPY) SEREM A MSM COISA
        this.$store.dispatch('sessions/setEditableItemTopic', data) 
      },

      async saveHandler(){
        this.saved = !this.saved
        // refatorar setLocalTopic pra raproveiar o codigo pra que né? aiaiai...mas baile q segue
        const topic_category = this.categories.find(cat => cat.name === this.localTopic.topicName)
        var topic = {
          description: this.localTopic.description,
          id: this.topic.id,
          session: this.topic.session,
          session_date: this.topic.session_date,
          title: this.localTopic.title,
          topic_category: topic_category.id,
          topic_category_details: {
            color: this.localTopic.color,
            topicName: this.localTopic.topicName,
            name: this.localTopic.topicName,
            id: topic_category.id
          },
          topicIndex: this.topicIndex,
        }
        var payload = {
          id: topic.session, // o id dessa forma que eh o pulo do gato pra funcionar zero bala na filtragem por categorias
          patient: this.editedItemEditableClone.patient,
          patient_name: this.editedItemEditableClone.patient_name,
          // session_date: this.editedItemEditableClone.session_date, // irrelevante e poderia dar ruim
          topics: [topic],
          partial_update: true
        }
      
        await this.$store.dispatch('sessions/saveSession', payload)
        
        // n pode ser do editedItemEditableClone.topics se nao vai parecer que salvou
        // ou seja, vai parecer que cliquei em salvar em um topico que na real nao salvei
      
        var currSection = await this.getSessionById(topic.session)
        
        const findId = topic.id
        var tgtTopicIdx = currSection.topics.findIndex((topic) => {return topic.id == findId})
        currSection.topics[tgtTopicIdx] = topic
        payload.topics = currSection.topics
        payload.session_date = currSection.session_date
        payload.created_at = currSection.created_at
        this.$store.commit('dashboard/updateDashboardSession', {editedItem: payload})

      },

      async getSessionById(id){ // so pra dar td certo no update de varios topicos de sessoes dif
        const tgtSession = await axios.get(
          `/sessions/sessions/${id}/`)
        .then(res => {
          return res.data
        })
        .catch(err => {
          if(err.response) // client recebeu resposta de erro: (4xx,5xx etc)
            console.log("error: bad response: ")
          else if (err.request)// response nunca recebida
            console.log("error: never received a response: ")
          else 
            console.log("error: ")
        })
        return tgtSession
      },
      setColor(choice){
        const obj = Object.assign({}, this.categories.find(obj => obj.name === choice))
        var editedItemCopy = Object.assign({}, this.editedItem)
        this.localTopic.color = obj.color
      },
      
      resetSavedStatus(){
        this.saved = false
      },

      escapeRegExp(stringToGoIntoTheRegex) {
        return stringToGoIntoTheRegex.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      },

      saveLastWordTyped(k, keyCode){
        //console.log("k:", k, "code ", keyCode)
        //ver https://www.cambiaresearch.com/articles/15/javascript-char-codes-key-codes
        
        if (k == ']'||k == '['){
          k = ']'
        }
        if ( k == '{' || k == '{' || k == '}'){
          k = '{'
        }

        if (keyCode > 46){
          //console.log("caso a")
          this.lastWordTyped += k != 'Dead' ? k : ''
        }
        else if(keyCode < 46 && ((keyCode != 8) && keyCode != 16)){
          //console.log("caso b")
          //dif de shfit tb pois shift precede o { num teclado normal
          this.clearLastWord()
        } 
        else{
          //console.log("c?", keyCode, " e k ", k)
          if (keyCode === 8) //backspace
            this.lastWordTyped = this.lastWordTyped.slice(0, -1) //tudo antes do backspace
        }
      },
      
      clearLastWord(){
        this.lastWordTyped = ''
      },

      // ref boa: https://stackoverflow.com/questions/55478646/input-cursor-jumps-to-end-of-input-field-on-input-event
      handlerInputPosition(e, mappedWordLen){ // inpede que o input vá pro final dps de trocar
        const el = e.target;
        const ammount = mappedWordLen > 0 ? mappedWordLen : 0
        const sel = el.selectionStart + ammount

        el.value = this.localTopic.description
        
        this.$nextTick(() => {
          el.setSelectionRange(el.selectionStart, sel);
        });
      },

      getLastWordTyped(e){
        this.saveLastWordTyped(e.key, e.keyCode)
        let match = this.dictionary.find( map => map['word_key'] === this.lastWordTyped ) //caso padrao

        if (match != undefined){
          // simobolos especiais soltos ex: {/ entrarao aqui e n sofrerao efeito ;) (n sofre efeito pour casauda da regex sem scape hehe)
          var regex = new RegExp("\\b" + this.lastWordTyped + "\\b", 'g')
          this.localTopic.description = this.localTopic.description.replace(regex, match.word_map);

          //console.log("match 1 ")
        } 
        else {
          match = this.dictionary.find( map => this.lastWordTyped.includes(map['word_key']) && map['word_key'].match(/[-\/\\^$*+?.()|[\]{}]/g) != null) //o  match garante que só keys com caracteres especiais que entraram no if
          //console.log("match 2 ")
          if(match != undefined){
            var replace_tgt = this.escapeRegExp(match.word_key)
            //console.log("match 2.1 ")
            var regex = new RegExp("\\w" + replace_tgt + "+", 'g')
            //console.log("regex ", regex, " veja ",this.lastWordTyped)
            var replaceWIth = this.lastWordTyped.slice(this.lastWordTyped.length -2,-1) + match.word_map// this.lastWordTyped.slice(2).slice(0,-1) + match.word_map
            //console.log("replace eee ", replaceWIth, " las ", this.lastWordTyped)
            this.localTopic.description = this.localTopic.description.replace(regex, replaceWIth)
          } else {
            console.log("--")
          }
        }
        if (match != undefined)
        this.handlerInputPosition(e, match != undefined ? match.word_map.length : 0)
        this.resetSavedStatus()
      }
    },
  }
</script>

<style>
.custom-shadow {
  border-radius: 20px;
  box-shadow: 0px 5px 5px grey;
}

/* .focus-wrapper {
  outline:none;
} */

.categories-options {
  border-radius: 10px;
}

.my-title {
  border-radius: 10px;

}


.my-text-area {
  border-radius: 10px;
  background-color: white;
}


[text-narrow] {
  line-height: 1.3 !important;
}

</style>